import React, {useCallback, useEffect, useState} from 'react'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../customComponents/customInputs/customInput/CustomInput'
import YourProcessChart from './yourProgress/YourProcessChart'
import {useSelector} from 'react-redux'
import {goalTrackerDataAction} from '../../../../../redux/actions/DashboardActions'
import {getGoalTrackerDataSelector} from '../../../../../redux/selectors/DashboardSelectors'
import {useDispatch} from 'react-redux'
import {getUserDataSelector} from '../../../../../redux/selectors/AuthSelectors'
import 'primeicons/primeicons.css'
import {setGoalTrackerService} from '../../../../../services/Services'
import CustomLoader from '../../../../../customComponents/customLoader/CustomLoader'
import {
    ADD_GOAL_TRACKER_VALIDATION_FIELD,
    goalTackerValidation,
} from '../../../../../validations/validations'
import {isEmptyObjectValue} from '../../../../../helpers/CommonHelpers'
import useSequifiConfig from '../../../../../sequiConfig/useSequifiConfig'
import Show from '../../../../../customComponents/show/Show'
import AccessRights from '../../../../../accessRights/AccessRights'
import {allPermissionsAccess} from '../../../../../accessRights/useCustomAccessRights'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../../customComponents/customButtton/CustomButton2'

const GoalTracker = ({filter}) => {
    const goalTrackerData = useSelector(getGoalTrackerDataSelector)
    const loggedUser = useSelector(getUserDataSelector)

    const [loading, setLoading] = useState(false)
    const [fullLoading, setFullLoading] = useState(false)
    const [miniLoading, setMiniLoading] = useState(false)
    const [earnings, setEarnings] = useState(goalTrackerData?.weekly_set_value?.earning)
    const [accounts, setAccounts] = useState(goalTrackerData?.weekly_set_value?.account)
    const [kwSold, setKWSold] = useState(goalTrackerData?.weekly_set_value?.kw_sold)
    const {basedOnCompanyType} = useSequifiConfig()
    // validation errors
    // const [earningError, setEarningError] = useState(null)
    // const [accountsError, setAccountsError] = useState(null)
    // const [kwSoldError, setKwSoldError] = useState(null)
    const [addGoalTrackerError, setAddGoalTrackerError] = useState(
        ADD_GOAL_TRACKER_VALIDATION_FIELD
    )

    const dispatch = useDispatch()
    useEffect(() => {
        getGoalTrackerData()
    }, [filter])

    useEffect(() => {
        setEarnings(goalTrackerData?.weekly_set_value?.earning)
        setAccounts(goalTrackerData?.weekly_set_value?.account)
        setKWSold(goalTrackerData?.weekly_set_value?.kw_sold)
    }, [goalTrackerData])

    const getGoalTrackerData = useCallback(() => {
        if (goalTrackerData) setLoading(true)
        else setFullLoading(true)
        const body = {
            user_id: loggedUser?.id,
            filter: filter,
        }
        dispatch(goalTrackerDataAction(body)).finally(() => {
            if (goalTrackerData) setLoading(false)
            else setFullLoading(false)
            setMiniLoading(false)
        })
    }, [dispatch, filter])

    const refreshGoalTracker = () => {
        setMiniLoading(true)
        getGoalTrackerData()
    }

    const onSetGoal = () => {
        const body = {
            user_id: loggedUser?.id,
            earning: earnings,
            account: accounts,
            kw_sold: kwSold,
            filter: filter,
        }
        const validationErrors = goalTackerValidation(body)
        setAddGoalTrackerError(validationErrors)
        if (isEmptyObjectValue(validationErrors)) {
            setFullLoading(true)
            setGoalTrackerService(body)
                .then(() => {
                    getGoalTrackerData()
                })
                .catch(() => {})
                .finally(() => {
                    setFullLoading(false)
                    setAddGoalTrackerError(null)
                })
        }
    }
    return (
        <div
            className='bg-cmwhite p-18px sqcard-shadow flex-grow-1 '
            style={{
                fontSize: '14px',
                fontWeight: '600',
                fontFamily: 'Manrope',
                borderRadius: '10px',
                position: 'relative',
            }}
        >
            <CustomLoader visible={fullLoading} full />
            <div className='mb-6 d-flex text-sqGray900 text-md-bold' style={{position: 'relative'}}>
                Goal Tracker
            </div>
            <div className='d-flex gap-7 flex-wrap align-items-start w-100 justify-content-between'>
                <div className='w-225px flex-grow-1 '>
                    <div className='d-flex align-items-start gap-2 mb-18px'>
                        <div className='text-sqGray900 text-sm-bold'>
                            {filter == 'this_week'
                                ? 'Weekly'
                                : filter == 'this_year'
                                ? 'Yearly'
                                : filter == 'this_month'
                                ? 'Monthly'
                                : filter == 'this_quarter'
                                ? 'Quartely'
                                : filter == 'last_12_months'
                                ? 'Last 12 Month'
                                : null}{' '}
                            Goals
                        </div>
                        <div
                            className={`pi ${
                                miniLoading ? 'pi-spin' : null
                            } pi-sync cursor-pointer text-sqGray900`}
                            style={{fontSize: 20}}
                            id='Announcement_Goal_Tracker_Reset_Weekly-Goals_Icon'
                            onClick={() => refreshGoalTracker()}
                        ></div>
                    </div>
                    <form className='' onSubmit={onSetGoal}>
                        {/* 1 */}
                        <div className='mb-7'>
                            <div className='d-flex gap-2 align-items-center mb-4'>
                                <div className='text-xs-semibold text-sqGray700 required min-w-75px  '>
                                    Earnings
                                </div>
                                <div className='flex-grow-1'>
                                    <CustomInput
                                        id='Announcement_Goal_Tracker_Earnings_Input'
                                        errorMessage={addGoalTrackerError?.earningError}
                                        type={INPUT_TYPE.currency}
                                        prefixText='$'
                                        onChange={(e) => setEarnings(e.target.value)}
                                        value={earnings}
                                    />
                                </div>
                            </div>
                            {/* 2 */}
                            <div className='d-flex gap-2 align-items-center mb-4'>
                                <div className='text-xs-semibold text-sqGray700 required min-w-75px   '>
                                    Accounts
                                </div>
                                <div className='flex-grow-1'>
                                    <CustomInput
                                        id='Announcement_Goal_Tracker_Accounts_Input'
                                        errorMessage={addGoalTrackerError?.accountErorr}
                                        type={INPUT_TYPE.number}
                                        onChange={(e) => setAccounts(e.target.value)}
                                        value={accounts}
                                    />
                                </div>
                            </div>
                            {/* 3 */}
                            <div className='d-flex gap-2 align-items-center mb-4'>
                                <div className='text-xs-semibold text-sqGray700 required min-w-75px   '>
                                    <Show>
                                        <Show.When isVisible={basedOnCompanyType.isCompanyTypePest}>
                                            Revenue:
                                        </Show.When>
                                        <Show.Else>
                                            {basedOnCompanyType?.primaryUnitType} Sold
                                        </Show.Else>
                                    </Show>
                                </div>
                                <div className='flex-grow-1'>
                                    <CustomInput
                                        id='Announcement_Goal_Tracker_KW_Sold_Input'
                                        errorMessage={addGoalTrackerError?.kw_soldError}
                                        type={INPUT_TYPE.number}
                                        onChange={(e) => setKWSold(e.target.value)}
                                        value={kwSold}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <AccessRights
                                customCondition={
                                    allPermissionsAccess.standard.dashboard.dashboard.edit
                                }
                            >
                                <CustomButton2
                                    width={`100`}
                                    id='Announcement_Goal_Tracker_Set_Goals_Btn'
                                    buttonType={BUTTON_TYPE.primary}
                                    buttonLabel='Set Goals'
                                    onClick={onSetGoal}
                                    buttonSize={BUTTON_SIZE.sm}
                                />
                            </AccessRights>
                        </div>
                    </form>
                </div>

                <div className='w-250px flex-grow-1'>
                    <YourProcessChart
                        graphdata={goalTrackerData}
                        targetGoal={goalTrackerData?.weekly_set_value}
                        achievedGoal={goalTrackerData?.get_value}
                    />
                </div>
            </div>
        </div>
    )
}

export default GoalTracker
