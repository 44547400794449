import moment from 'moment'
import {getValidDate} from '../constants/constants'
import {customMoment} from '../customComponents/customMoment/customMoment'
import {jsonToFormData} from '../helpers/CommonHelpers'

import authAxios from './authAxios'
import EndPoints from './EndPoints'
import withoutAuthAxios from './withoutAuthAxios'
import normalAxios from './normalAxios'
import {MULTITENANT_API_BASE_URL} from '../constants/SERVERS'

const getActiveUserFromResponse = (res) => {
    let response = {...res}
    let data = [...res?.data]
    const filteredData = data?.filter((item) => !item?.dismiss)
    response.data = filteredData
    return response
}
export const callThirdPartyApiService = ({url = '', responseType = 'json', type = ''}) =>
    withoutAuthAxios.post(
        EndPoints.callThirdPartyApi,
        {
            url,
            type,
        },
        {
            responseType,
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

export const getBitbucketBranchesService = () =>
    withoutAuthAxios.get(EndPoints.getBitbucketBranches)

export const subDomainAvailiblityCheckService = (sub_domain = '') =>
    normalAxios.post(`${MULTITENANT_API_BASE_URL}${EndPoints.subDomainAvailiblityCheck}`, {
        sub_domain,
    })

export const uploadImageToS3Service = (imageFile) =>
    authAxios.post(
        EndPoints.uploadImageToS3,
        jsonToFormData({
            image: imageFile,
        })
    )

// Google Maps
export const getCurrentTimeFromTimeZoneService = (timeZone) =>
    normalAxios(`${EndPoints.getCurrentTimeFromTimeZone}/${timeZone}`)
export const getLatLongByLocationService = (address) =>
    callThirdPartyApiService({
        url: `${EndPoints.getLatLongFromAddress}?language=en&address=${address}`,
        type: 'gmap',
    })

export const getPlaceAddressService = (address) =>
    callThirdPartyApiService({url: `${EndPoints.getPlaceAddress}?input=${address}`, type: 'gmap'})

export const getLocationAddressService = (address) =>
    callThirdPartyApiService({
        url: `${EndPoints.getLocationFromAddress}?sensor=false&&address=${address}`,
        type: 'gmap',
    })

export const getAddressFromReverseGeocodingService = (place_id) =>
    callThirdPartyApiService({
        url: `${EndPoints.getLocationFromAddress}?&sensor=false&place_id=${place_id}`,
        type: 'gmap',
    })

export const getTimeZoneFromLatLongService = (lat, long) =>
    callThirdPartyApiService({
        url: `${EndPoints.getTimeZoneFromLatLong}?location=${lat},${long}&timestamp=${
            customMoment.todayDate().getTime() / 1000
        }`,
        type: 'gmap',
    })

// Login
export const loginService = (data) => withoutAuthAxios.post(EndPoints.login, data)
//Logout
export const logoutService = () => authAxios.post(EndPoints.logout)
//forgot password
export const forgotPasswordService = (data) => withoutAuthAxios.post(EndPoints.forgotPassword, data)
export const resetPasswordService = (data) => withoutAuthAxios.post(EndPoints.resetPassword, data)
export const changePasswordService = (data) => authAxios.post(EndPoints.changePassword, data)
export const updateDeviceTokenService = (device_token) =>
    authAxios.post(EndPoints.updateDeviceToken, {device_token})
export const resetAppService = () => authAxios.post(EndPoints.resetApp)

export const getUserProfileService = () => authAxios.get(EndPoints.getUserProfile)

export const getCompanySettingService = () => authAxios.get(EndPoints.getCompanySetting)
export const updateCompanyProfileService = (body) =>
    authAxios.post(EndPoints.updateCompanyProfile, jsonToFormData(body))

export const updateCompanySettingService = (type, status) =>
    authAxios.post(EndPoints.updateCompanySetting, {type, status})
export const updateCompanyReconSettingV1Service = (type, status) =>
    authAxios.post(EndPoints.updateCompanyReconSettingV1, {type, status})

export const loginAsUserService = (data) => authAxios.post(EndPoints.loginAsUser, data)
export const switchToAdminService = () => authAxios.post(EndPoints.switchToAdmin)

// Admin > Setup > Company Margin
export const getCompanyMarginService = () => authAxios.get(EndPoints.getCompanyMargin)
export const updateCompanyMarginService = (data) =>
    authAxios.post(EndPoints.updateCompanyMargin, data)

/**
 * Company > Setting > Overrides
 */
export const getOverridesOnTwoAccountStatusService = () =>
    authAxios.get(EndPoints.getOverridesOnTwoAccountStatus)
export const updateOverridesOnTwoAccountStatusService = (data) =>
    authAxios.post(EndPoints.updateOverridesOnTwoAccountStatus, data)
export const getUpfrontSettingService = () => authAxios.get(EndPoints.getCompanyUpfrontSetting)
export const updateCompanyUpfrontSettingService = (data) =>
    authAxios.put(EndPoints.updateCompanyUpfrontSetting, data)
export const getAdvanceSettingService = () => authAxios.get(EndPoints.getCompanyUpfrontSetting)

/**
 *
 * Company > Setting > Reconcilation
 */
export const getCompanyReconciliationService = () =>
    authAxios.get(EndPoints.getCompanyReconciliation)
export const deleteReconciliationPeriodService = (data) =>
    authAxios.delete(EndPoints.deleteReconciliationPeriod, {data})
export const updateCompanyReconcilationService = (data) =>
    authAxios.put(EndPoints.updateReconciliation, data)
export const getDeductAnyAvailableReconciliationService = () =>
    authAxios.get(EndPoints.getDeductAnyAvailableReconciliation)
export const updateDeductAnyAvailableReconciliationService = (data) =>
    authAxios.post(EndPoints.updateDeductAnyAvailableReconciliation, data)

export const updateReconciliationSettingStatusV1Service = (data) =>
    authAxios.post(EndPoints.updateReconciliationSettingStatusV1, data)

// getCompanyProfileService
export const getCompanyProfileWithoutAuthService = () =>
    withoutAuthAxios.get(EndPoints.getCompanyProfileWithoutAuth)
export const getAllLocationListService = () => authAxios.get(EndPoints.getAllLocationList)
export const getCompanyProfileService = (signal) =>
    authAxios.get(EndPoints.getCompanyProfile, {signal})
//getTierLevel
export const getTierLevelService = () => authAxios.get(EndPoints.getTierDuration)
export const getAllStatesAndCitiesService = () => authAxios.get(EndPoints.getAllStatesAndCities)
export const getUsedStateService = () => authAxios.get(EndPoints.getUsedStates)

// getCompanyGlobalReconciliationService
export const getCompanyGlobalReconciliationService = () =>
    authAxios.get(EndPoints.getCompanyGlobalReconciliation)

//getsetupactiveinactive
export const getSetupActiveInactiveService = (data) =>
    authAxios.post(EndPoints.getSetupActiveInactive, data)

export const getPayrollService = () => authAxios.get(EndPoints.getSettingPayroll)

export const getMarkettingReconciliationService = () =>
    authAxios.get(EndPoints.getMarkettingReconciliation)
export const updateMarkettingReconciliationService = (data) =>
    authAxios.put(EndPoints.updateMarkettingReconciliation, data)

export const getMarginofDiffrenceService = () => authAxios.get(EndPoints.getmarginofdiffrence)

export const updateMarginofDiffrenceService = (data) =>
    authAxios.put(EndPoints.updatemarginofdiffrence, JSON.parse(JSON.stringify(data)))

export const getLocationsListService = (params, signal) =>
    authAxios.get(EndPoints.getLocationsList, {
        params,
        signal,
    })
export const exportLocationDataService = (params) =>
    authAxios.get(EndPoints.exportLocationData, {params})

export const deleteLocationService = (id) => authAxios.delete(`${EndPoints.deleteLocation}/${id}`)
export const getCostCenterListService = (data, signal) =>
    authAxios.post(EndPoints.getCostCenterList, data, {signal})
export const getSingleNotificationService = (data) =>
    authAxios.post(EndPoints.getSingleNotification, data)
export const getAllNotificationService = (data) =>
    authAxios.post(EndPoints.getAllNotification, jsonToFormData(data))

export const getCostCenterListbyIDService = (id) =>
    authAxios.get(`${EndPoints.getCostCenterListbyID}/${id}`)

export const getDepartmentListService = (params, signal) =>
    authAxios.get(EndPoints.getDepartmentList, {params, signal})

export const getuserListbyDepartmentIDService = (params, signal) =>
    authAxios.get(`${EndPoints.userListbyDepartmentId}`, {params, signal})

export const getPositionByDeparmentService = () => authAxios.get(EndPoints.getPositionByDepartment)
export const updateCostCenterService = (id, data) =>
    authAxios.put(`${EndPoints.updatecostcenter}/${id}`, data)
export const getIncompleteAccountAlertService = () =>
    authAxios.get(EndPoints.getIncompleteAccountAlert)

// Postion
export const getuserbyPositionIDService = (id, params, signal) =>
    authAxios.get(`${EndPoints.userbyPositionID}/${id}`, {params, signal})

export const getuserListbyOfficeIDService = (id, params, signal) =>
    authAxios.get(`${EndPoints.userListbyOfficeID}/${id}`, {params, signal})

export const getCompleteAccountAlertService = () => authAxios.get(EndPoints.getCompleteAccountAlert)

export const getPositionListService = (params, signal) =>
    authAxios.get(EndPoints.getPositionList, {params, signal})
export const getAllPositionListService = (params) =>
    authAxios.get(EndPoints.getAllPositionList, {params})
export const getPositionListByDepartmentIdService = (department_id) =>
    authAxios.get(`${EndPoints.getPositionListByDepartmentId}/${department_id}`)
export const getPositionSettingService = (position_id) =>
    authAxios.get(`${EndPoints.getPositionSetting}/${position_id}`)
export const deletePositionService = (id) =>
    authAxios.delete(`${EndPoints.deletePositionPlan}/${id}`)

export const addLocationService = (data) => authAxios.post(EndPoints.addlocation, data)
export const updateLocationService = (data) => authAxios.put(EndPoints.updatelocation, data)
export const getStateListService = () => authAxios.get(EndPoints.getStateList)
export const getAllOfficeLocationService = () => authAxios.get(EndPoints.getAllOfficeLocation)
export const getAllStatesWithOfficesService = () => authAxios.get(EndPoints.getAllStatesWithOffices)
export const upComingLocationRedlinesService = (id) =>
    authAxios.get(`${EndPoints.upComingLocationRedlines}/${id}`)

export const getCityListService = (data) => authAxios.get(`${EndPoints.getcityList}/${data}`)
export const getCostCenterDropdownService = () => authAxios.get(EndPoints.getcostcenter)
export const getParentCostCenterDropdownService = (signal) =>
    authAxios.get(EndPoints.getParentCostCenterList, {signal})
export const getRedlineDataFromLocation = (body) =>
    authAxios.post(EndPoints.getRedlineFromLocation, body)

export const getRecuiterFilterService = (data, signal) =>
    new Promise((resolve, reject) => {
        authAxios
            .post(EndPoints.getRecuirter, {filter: data}, {signal})
            .then((res) => {
                let response = getActiveUserFromResponse(res)
                resolve(response)
            })
            .catch(reject)
    })

export const addCostCenterService = (data) => authAxios.post(EndPoints.addcostcenter, data)
export const getDepartmentDropdownService = (signal) =>
    authAxios.get(EndPoints.getdartmentdropdown, {signal})
export const AddDepartmentService = (data) => authAxios.post(EndPoints.adddepartment, data)
export const getMainPositionDropdownService = () => authAxios.get(EndPoints.getposition)

export const AddPositionService = (data) => authAxios.post(EndPoints.addposition, data)
export const updatePositionsService = (id, data) =>
    authAxios.put(`${EndPoints.updatePositions}/${id}`, data)
export const updatePositionService = (positionId, data) =>
    authAxios.put(`${EndPoints.updatePosition}/${positionId}`, data)
export const getPositionByIdService = (id) => authAxios.get(`${EndPoints.getPositionById}/${id}`)
export const deletePositionDeductionService = (id) =>
    authAxios.delete(`${EndPoints.deletePositionDeduction}/${id}`)

export const getApplicableoverridesV1Service = (body) =>
    authAxios.post(EndPoints.getApplicableoverridesV1, body)
export const checkReconciliationSettingService = (params) =>
    authAxios.get(EndPoints.checkReconciliationSetting, {params})

// export const getAllManagerListService = (office_id = null) =>
//     new Promise((resolve, reject) => {
//         authAxios
//             .get(EndPoints.getAllManagerlist, {
//                 params: {
//                     office_id,
//                 },
//             })
//             .then((res) => {
//                 let response = getActiveUserFromResponse(res)
//                 resolve(response)
//             })
//             .catch(reject)
//     })

export const getAllManagerListService = (office_id = null, effective_date = null) =>
    new Promise((resolve, reject) => {
        authAxios
            .post(EndPoints.getManagerByOfficeAndEffectiveDate, {
                office_id,
                effective_date: effective_date ?? getValidDate(moment(), 'YYYY-MM-DD'),
            })
            .then((res) => {
                let response = getActiveUserFromResponse(res)
                resolve(response)
            })
            .catch(reject)
    })
export const reAssignManagerService = (data) => authAxios.put(EndPoints.reAssignManager, data)
export const getTeamListService = (office_id = '') =>
    authAxios.get(`${EndPoints.getteamlist}?office_id=${office_id}`)

export const addOnbrdingEmployeeService = (data) =>
    authAxios.post(EndPoints.addonbrdingemployee, data)
export const addTierLevelService = (data) => authAxios.post(EndPoints.createtier, data)
export const getOnBoardingEmployeeListService = (params, signal) =>
    authAxios.get(EndPoints.getOnBoardingEmployeeList, {
        params,
        signal,
    })
export const exportOnBoardingEmployeeListService = (params) =>
    authAxios.get(EndPoints.exportOnBoardingEmployeeList, {
        params,
    })
export const deleteOnBoardingEmployeeListService = (id) =>
    authAxios.delete(`${EndPoints.deleteOnBoardingEmployeeList}/${id}`)
export const addSetupService = (data) => authAxios.post(EndPoints.setupPosition, data)

export const getOnbordingEmployeebyIdService = (id) =>
    authAxios.get(`${EndPoints.getonbordingemployeebyid}/${id}`)
export const deleteTierService = (data) => authAxios.delete(`${EndPoints.deletetier}/${data}`)
export const deletLocationService = (data) =>
    authAxios.delete(`${EndPoints.deletelocation}/${data}`)
export const sendEmailbyIdService = (
    data,
    resendOfferLetterType = '',
    resendDocType = '',
    params
) =>
    authAxios.get(
        `${EndPoints.sendemailbyid}/${data}${
            resendOfferLetterType ? `?type=${resendOfferLetterType}&documents=${resendDocType}` : ''
        }`,
        {params}
    )
export const resendParticularDocumentService = (data) =>
    authAxios.post(EndPoints.resendParticularDocument, data)

export const deletDepartmentService = (data) => authAxios.put(`${EndPoints.deletedepartment}`, data)
export const updateOnbordingEmployee = (data) =>
    authAxios.put(`${EndPoints.updateonbordingemployee}/${data}`)
export const updateDepartmentService = (data) => authAxios.put(EndPoints.updatedepartment, data)
export const disableCostCenterService = (data) =>
    authAxios.put(`${EndPoints.disablecostcenter}`, data)
export const updateTierLevelService = (data) => authAxios.put(EndPoints.updatetierlevel, data)

export const addHiringLeadsService = (data) => authAxios.post(EndPoints.addhiringleads, data)
export const addLeadWithoutAuthService = (data) =>
    withoutAuthAxios.post(EndPoints.addLeadWithoutAuth, data)
export const checkDuplicateLeadServce = (data) => authAxios.post(EndPoints.checkDuplicateLead, data)
export const checkDuplicateLeadWithoutAuthService = (data) =>
    authAxios.post(EndPoints.checkDuplicateLeadWithoutAuth, data)
export const updateHiringLeadsService = (id, data) =>
    authAxios.put(`${EndPoints.addhiringleads}/${id}`, data)

export const getRecentlyHiredListService = (office_id) =>
    authAxios.get(EndPoints.getRecentlyHiredList, {
        params: {
            office_id,
        },
    })

export const getHiringProgressListService = (data, signal) =>
    authAxios.get(EndPoints.getHiringProgressList, {
        params: data,
        signal,
    })

//   Employee
export const getEmployeeProfileService = (employee_id) =>
    authAxios.get(`${EndPoints.getEmployeeProfile}/${employee_id}`)
export const getEmployeePersonalInfoService = (employee_id) =>
    authAxios.get(`${EndPoints.getEmployeePersonalInfo}/${employee_id}`)
export const getEmployeePackageService = (employee_id) =>
    authAxios.get(`${EndPoints.getEmployeePackage}/${employee_id}`)
export const getEmployeeBankingInfoService = (employee_id) =>
    authAxios.get(`${EndPoints.getEmployeeBankingInfo}/${employee_id}`)
export const getEmployeeTaxInfoService = (employee_id) =>
    authAxios.get(`${EndPoints.getEmployeeTaxInfo}/${employee_id}`)
export const updateEmployeeProfileService = (data) =>
    authAxios.post(EndPoints.updateEmployeeProfile, data)
export const updateEmployeePositionService = (data) =>
    authAxios.post(EndPoints.updateEmployeePosition, data)
export const updateEmployeeCommissionService = (data) =>
    authAxios.post(EndPoints.updateEmployeeCommission, data)
export const getUserRedlineService = (employee_id) =>
    authAxios.get(`${EndPoints.getUserRedline}/${employee_id}`)
export const updateUserAccountStatusService = (data) =>
    authAxios.post(EndPoints.updateUserAccountStatus, data)

export const getHiringProgressChartService = (data) =>
    authAxios.post(EndPoints.getHiringProgressChart, data)

export const getHireLeadsListService = (data, signal) =>
    authAxios.get(EndPoints.getHireLeadsList, {
        params: data,
        signal,
    })
export const deleteLeadService = (id) => authAxios.delete(`${EndPoints.deleteLead}/${id}`)
export const getUsersByManagerIdService = (manager_id) =>
    authAxios.get(`${EndPoints.getUsersByManagerId}/${manager_id}`)
export const transferEmployeeService = (data) => authAxios.post(EndPoints.transferEmployee, data)

//   Hire Now
export const hireNowStep1Service = (data) => authAxios.post(EndPoints.hireNowStep1, data)
export const updateHireNowStep1Service = (data) => authAxios.put(EndPoints.hireNowStep1, data)
export const hireNowStep2Service = (data) => authAxios.post(EndPoints.hireNowStep2, data)
export const hireNowStep3Service = (data) => authAxios.post(EndPoints.hireNowStep3, data)
export const hireNowStep4Service = (data) => authAxios.post(EndPoints.hireNowStep4, data)
export const hireNowStep5Service = (data) => authAxios.post(EndPoints.hireNowStep5, data)

export const postRescheduleInterviewService = (id, body) =>
    authAxios.post(`${EndPoints.postRescheduleInterview}/${id}`, body)
export const updateCommissionService = (id, body) =>
    authAxios.put(`${EndPoints.updatecommission}/${id}`, body)
export const sendUserCredentialService = (employee_id) =>
    authAxios.post(EndPoints.sendUserCredential, {employee_id})

export const resendWelcomeEmailService = (body) =>
    authAxios.post(EndPoints.resendWelcomeEmail, body)

// Toggle Alerts
export const putToggleAlertsService = (body) => authAxios.put(EndPoints.putToggleAlerts, body)

export const scheduleInterviewService = (body) => authAxios.post(EndPoints.scheduleInterview, body)

export const getInterviewTimeSlotService = (body) =>
    authAxios.post(EndPoints.getInterviewTimeSlot, body)

export const changeLeadStatusService = (lead_id, status) =>
    authAxios.post(EndPoints.changeLeadStatus, {
        lead_id,
        status,
    })
export const exportLeadsDataService = (params) => authAxios.get(EndPoints.exportLeadsData, {params})
export const importLeadsDataService = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.importLeadsData, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })

export const hiringAssignService = (body) => authAxios.post(EndPoints.hiringAssign, body)

export const getLeadCommentsService = (id) => authAxios.get(`${EndPoints.getLeadComments}/${id}`)
export const addLeadCommentsService = (body) => authAxios.post(EndPoints.addLeadComments, body)
export const addOnBoardingCommentsService = (body) =>
    authAxios.post(EndPoints.addOnBoardingComments, body)
export const replyLeadCommentsService = (body) => authAxios.post(EndPoints.replyLeadComments, body)
export const getEmployeeDocumentCommentService = (id) =>
    authAxios.get(`${EndPoints.getEmployeeDocumentComment}/${id}`)

export const getLeadByIdServices = (id) => authAxios.get(`${EndPoints.getLeadById}/${id}`)
export const hireEmployeeDirectlyService = (body) =>
    authAxios.post(EndPoints.hireEmployeeDirectly, {...body, hiring_type: 'Directly'})
export const getHiringCalendarEventsService = (body) =>
    authAxios.post(EndPoints.getHiringCalendarEvents, body)
export const getOnBoardingConfigurationService = (body) =>
    authAxios.post(EndPoints.getOnBoardingConfiguration, body)
export const addOnBoardingConfigurationService = (body) =>
    authAxios.post(EndPoints.addOnBoardingConfiguration, body)
export const addOrUpdateOnboardingDynamicAttributesService = (body) =>
    authAxios.post(EndPoints.addOrUpdateOnboardingDynamicAttributes, body)
export const deleteOnboardingDynamicAttributesService = (body) =>
    authAxios.post(EndPoints.deleteOnboardingDynamicAttributes, body)
export const updateHireDateOfEmployeeService = (user_id, created_at) =>
    authAxios.post(EndPoints.updateHireDateOfEmployee, {user_id, created_at})
export const getEmployeeDataByCompensationTypeService = (body, signal) =>
    authAxios.post(EndPoints.getEmployeeDataByCompensationType, body, {signal})
export const getTemplateListByCategoryService = (categery_id) =>
    authAxios.post(EndPoints.getSequiDocsTemplatesByCategory, categery_id)
export const getSequiDocsAgreementListService = () =>
    authAxios.get(EndPoints.getSequiDocsAgreementList)

// postMarketingDeal
export const postMarketingDealService = (body) => authAxios.post(EndPoints.postMarketingDeal, body)

//Post Incomplete Alert
export const createIncompleteAccountAlertService = (body) =>
    authAxios.post(EndPoints.createIncompleteAccountAlert, body)

//Email Notification
export const getEmailNotificationSettingService = () =>
    authAxios.get(EndPoints.getEmailNotificationSetting)
export const updateEmailNotificationSettingService = (body) =>
    authAxios.post(EndPoints.updateEmailNotificationSetting, body)
export const updateDomainSettingService = (body) =>
    authAxios.post(EndPoints.updateDomainSetting, body)
export const getDomainSettingService = () => authAxios.get(EndPoints.getDomainSetting)
export const addDomainSettingService = (body) => authAxios.post(EndPoints.addDomainSetting, body)
export const deleteDomainSettingService = (id) =>
    authAxios.delete(`${EndPoints.deleteDomainSetting}/${id}`, null)

/**
 * Admin > Settings > Setup > Schedule Payroll
 */
// Get Pay Frequency Type
export const getPayFrequencyTypeService = () => authAxios.get(EndPoints.getPayFrequencyType)

// Update Company Payroll
export const updateCompanyPayrollService = (body) =>
    authAxios.put(EndPoints.updateCompanyPayroll, body)

/**
 * Integration
 */
export const getIntegrationListService = () => authAxios.get(EndPoints.getIntegtationList)
export const getIntegrationByIdService = (id) =>
    authAxios.get(`${EndPoints.getIntegtationById}${id}`)
export const addIntegratioSettingnService = (data) =>
    authAxios.post(EndPoints.addIntegtationSetting, data)
export const updateIntegratioSettingnService = (data) =>
    authAxios.post(EndPoints.updateIntegtationSetting, data)
export const connectDisconnectIntegtationSettingService = (id) =>
    authAxios.post(EndPoints.connectDisconnectIntegtationSetting, {id})
export const getIntegrationLogsService = (body) =>
    authAxios.post(EndPoints.getIntegrationLogs, body)
export const syncHubSpotDataService = () => authAxios.post(EndPoints.syncHubSpot)

/**
 * Management
 */
export const getEmployeeListService = (data, params, signal) =>
    authAxios.post(EndPoints.getEmployeeList, data, {
        params,
        signal,
    })
export const getEmployeeListByLocationService = (locations, params) =>
    authAxios.post(EndPoints.getEmployeeListByLocation, {locations}, {params})
export const filterEmployeeListService = (filter, params) =>
    authAxios.post(EndPoints.filterEmployeeList, {filter}, params)
export const addEmployeeDocumentByIdService = (data) =>
    authAxios.post(EndPoints.addEmployeeDocumentById, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
export const getEmployeeDocumentByIdService = (id) =>
    authAxios.get(`${EndPoints.getEmployeeDocumentById}/${id}`)
export const getEmployeeSequiDocDocumentByIdService = (id) =>
    authAxios.get(`${EndPoints.getEmployeeSequiDocDocumentById}/${id}`)
export const getOnBoardingEmployeeSequiDocDocumentByIdService = (id) =>
    authAxios.get(`${EndPoints.getOnBoardingEmployeeSequiDocDocumentById}/${id}`)
export const updateEmployeeDocumentByIdService = (data) =>
    authAxios.post(EndPoints.updateEmployeeDocumentById, data)
export const deleteEmployeeDocumentByIdService = (data) =>
    authAxios.post(EndPoints.deleteEmployeeDocumentById, data)
export const getDocumentTypeListService = () => authAxios.get(EndPoints.getDocumentTypeDropdown)
export const getEmployeeChartService = (id) => authAxios.get(`${EndPoints.getEmployeeChart}/${id}`)
export const updateEmployeeGroupService = (data) =>
    authAxios.post(EndPoints.updateEmployeeUserGroup, data)
export const updateUserImageService = (data) =>
    authAxios.post(EndPoints.updateUserImage, jsonToFormData(data))
export const getUserOverideService = (id) => authAxios.get(`${EndPoints.userNetworkOveride}/${id}`)
export const getOverrideOnUserService = (id) => authAxios.get(`${EndPoints.overrideOnUser}/${id}`)
export const addManualOverrideService = (data) => authAxios.post(EndPoints.addManualOverride, data)
export const addManualOverrideFromService = (data) =>
    authAxios.post(EndPoints.addManualOverrideFrom, data)
export const getManualOverrideDataService = (body) =>
    authAxios.post(EndPoints.getManualOverrideData, body)
export const editManualOverrideFromService = (data) =>
    authAxios.post(EndPoints.editManualOverrideFrom, data)
export const editManualOverrideOnService = (data) =>
    authAxios.post(EndPoints.editManualOverrideOn, data)
export const changeOverrideStatusService = (body) =>
    authAxios.put(EndPoints.changeOverrideStatus, body)

export const exportEmployeeDataService = (params) =>
    authAxios.get(EndPoints.exportEmployeeData, {params})

export const getEmployeeAduitlogbyIdService = (data, signal) =>
    authAxios.post(EndPoints.getEmployeeAduitlogbyId, data, {signal})

export const getAuditLogforallUserService = (
    search = '',
    start_date = '',
    end_date = '',
    perpage = 10,
    page,
    signal
) =>
    authAxios.get(EndPoints.getAuditLogforallUser, {
        signal,
        params: {
            start_date,
            end_date,
            perpage,
            page,
            search,
        },
    })

/**
 * Sequi Docs
 */
export const getSequiDocsTemplateCategoriesService = () =>
    authAxios.post(EndPoints.getSequiDocsTemplateCategories)
export const getSequiDocsTemplatesService = () => authAxios.get(EndPoints.getSequiDocsTemplates)
export const getSequiDocsTopTemplatesService = () =>
    authAxios.get(EndPoints.getSequiDocsTopTemplates)

export const getSequiDocsTemplateListByCategoryService = (categery_id) =>
    authAxios.post(EndPoints.getSequiDocsTemplatesByCategory, {categery_id})
export const getSequiDocsTemplateByIdService = (id) =>
    authAxios.get(`${EndPoints.getSequiDocsTemplateById}/${id}`)
export const getSequiDocsTemplateHistoryService = (id) =>
    authAxios.get(`${EndPoints.getTemplateHistory}/${id}`)

export const getSequiDocsTemplatesHistoryService = (id) =>
    authAxios.get(`${EndPoints.getSequifiDocTemplateHistory}/${id}`)

export const addSequiDocsTemplateService = (data) =>
    authAxios.post(EndPoints.addSequiDocsTemplate, data)
export const updateSequiDocsTemplateService = (id, data) =>
    authAxios.put(`${EndPoints.updateSequiDocsTemplate}/${id}`, data)
export const deleteSequiDocsTemplateService = (id) =>
    authAxios.delete(`${EndPoints.deleteSequiDocsTemplate}/${id}`)
export const searchSequiDocsTemplateService = (name) =>
    authAxios.post(EndPoints.searchSequiDocsTemplate, {name})
export const sendSequiDocsTemplateService = (template_id, data) =>
    authAxios.post(`${EndPoints.sendTemplate}/${template_id}`, data)
export const assignSequiDocsTemplateService = (data) =>
    authAxios.post(EndPoints.assignTemplate, data)
export const getDocumentsListService = (data) => authAxios.post(EndPoints.getDocumentsList, data)
export const getNewDocumentsListService = (params, signal) =>
    authAxios.get(EndPoints.getNewDocumentsList, {params, signal})

export const addTemplateCategoryService = (data) =>
    authAxios.post(EndPoints.addTemplateCategory, data)
export const updateTemplateCategoryService = (id, data) =>
    authAxios.post(`${EndPoints.updateTemplateCategory}/${id}`, data)
export const deleteTemplateCategoryService = (id) =>
    authAxios.delete(`${EndPoints.deleteSequiDocsCategory}/${id}`)
export const addTemplateSettingStep1Service = (data) =>
    authAxios.post(EndPoints.addTemplateSettingStep1, data)
export const updateTemplateSettingService = (data) =>
    authAxios.post(EndPoints.updateTemplateSetting, data)

export const addTemplateAgreementStep4Service = (data) =>
    authAxios.post(EndPoints.addTemplateAgreementStep4, data)
export const addTemplateSignatureStep2Service = (data) =>
    authAxios.post(EndPoints.addTemplateSignatureStep2, data)
export const addTemplateEditTemplateStep3Service = (data) =>
    authAxios.post(EndPoints.addTemplateEditTemplateStep3, data)
export const getSingleTemplateListService = (id, page, perpage) =>
    authAxios.get(`${EndPoints.getTemplateListByCategory}/${id}`, {
        params: {
            page,
            perpage,
        },
    })
export const getEmailTemplatesListService = () => authAxios.get(EndPoints.getEmailTemplatesList)

export const updateEmailTemplateService = (id, body) =>
    authAxios.post(`${EndPoints.updateEmailTemplate}/${id}`, body)

export const deleteSequiDocsParticularTemplateService = (data) =>
    authAxios.post(EndPoints.deleteSequiDocsParticularTemplate, data)
export const getTemplateDetailService = (id) =>
    authAxios.get(`${EndPoints.getTemplateDetail}/${id}`)
export const sendOrUseSequiDocTemplateService = (id, body) =>
    authAxios.post(`${EndPoints.useOrSendSequiDocTemplate}/${id}`, body)

export const sequiDocUseTemplateService = (body) =>
    authAxios.post(EndPoints.useSequiDocsTemplate, body)

export const testParticularTemplateService = (body) =>
    authAxios.post(EndPoints.testParticularTemplate, body)
export const downloadParticularTemplateService = (body) =>
    authAxios.post(EndPoints.downloadParticularTemplate, body)

export const sendSequiDocTestTemplateEmailService = (id, body) =>
    authAxios.post(`${EndPoints.sendSequiDocTestTemplateEmail}/${id}`, body)

export const getSmSequiDocDataService = () => authAxios.get(EndPoints.getSmSequiDocData)
export const searchEmployeeToSendDocService = (body) =>
    authAxios.post(EndPoints.searchEmployeeToSendDoc, body)

/**
 * Requests & Approval
 */
export const getAdjustmentTypeListService = () => authAxios.get(EndPoints.getAdjustmentTypeList)
export const getAlLRequestsListService = (params, signal) =>
    authAxios.get(EndPoints.getAlLRequestsList, {params, signal})
export const searchRequestService = ({filter, page}) =>
    authAxios.post(EndPoints.searchRequest, {filter, page})
export const getRequestDetailsByRequestNumberService = (request_no) =>
    authAxios.get(`${EndPoints.getRequestDetailsByRequestNumber}/${request_no}`)
export const updateStatusOfRequestService = (request_id, status) =>
    authAxios.post(EndPoints.updateStatusOfRequest, {request_id, status})
export const addRequestService = (data) => authAxios.post(EndPoints.addRequest, data)
export const addRequestApprovalCommentService = (data) =>
    authAxios.post(EndPoints.addRequestApprovalComment, jsonToFormData(data), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
export const getAllApprovalListService = (params, signal) =>
    authAxios.get(EndPoints.getAllApprovalList, {params, signal})

export const getApprovalOrHistoryListService = (params) =>
    authAxios.get(EndPoints.getApprovalOrHistoryList, {params})

export const searchApprovalService = (filter) => authAxios.post(EndPoints.searchApproval, {filter})
export const getApprovalHistoryService = (params) =>
    authAxios.get(EndPoints.approvalHistory, {params})
export const getPidByUserService = (id) => authAxios.get(`${EndPoints.getPidByUser}/${id}`)
export const exportApprovalHistoryService = (params) =>
    authAxios.get(EndPoints.exportApprovalHistory, {params})

//sm > Report
export const getManagerReportService = (data, signal) =>
    authAxios.post(EndPoints.getManagerReport, data, {signal})
export const getMySalesReportV1Service = (data) =>
    authAxios.post(EndPoints.getMySalesReportV1, data)
export const getMySalesChartService = (params) =>
    authAxios.post(EndPoints.getMySalesChart, null, {params})
export const getSalesGraphService = (params) =>
    authAxios.post(EndPoints.getSalesGraph, null, {params})
export const getSalesReportService = (data, signal) =>
    authAxios.post(EndPoints.getSalesReport, data, {signal})
export const getSalesAccountAndInstallRatioGraphService = (params) =>
    authAxios.post(EndPoints.getSalesAccountAndInstallRatioGraph, null, {params})
export const getReconcilliationReportV1Service = (data) =>
    authAxios.post(EndPoints.getReconcilliationReportV1, data)
export const getCustomerSaleTrackingService = (id) =>
    authAxios.get(`${EndPoints.getSaleTracking}/${id}`)

//Admin Report
export const getReconciliationReportService = (params, signal) =>
    authAxios.get(EndPoints.getReconciliationReport, {params, signal})
export const exportReconciliationReportV1Service = (params) =>
    authAxios.get(EndPoints.exportReconciliationReportV1, {params})
export const getSingleReconciliationReportService = (data, signal) =>
    authAxios.post(EndPoints.getSingleReconcliationReport, data, {signal})
export const exportPayrollReconciliationReportService = (data) =>
    authAxios.post(EndPoints.exportPayrollReconciliationReport, data)

//Standard > My Sales > Customer
export const getAdminReportV1Service = (data, signal) =>
    authAxios.post(EndPoints.getAdminCustomerListV1, data, {signal})
//Admin > Report > Sales
export const getAdminAllGraphsV1Service = (data) =>
    authAxios.post(EndPoints.getAdminAllGraphsV1, data)
export const exportAdminSalesService = (data) => authAxios.post(EndPoints.exportAdminSales, data)
export const getAdminSalesAccountDataService = (data) =>
    authAxios.post(EndPoints.getAdminSalesAccountData, data)
export const getAdminSalesContractsDataService = (data) =>
    authAxios.post(EndPoints.getAdminSalesContractsData, data)
export const getAdminSalesContractsIRDataService = (data) =>
    authAxios.post(EndPoints.getAdminSalesContractsIRData, data)
export const getAdminSalesAvarageDataService = (data) =>
    authAxios.post(EndPoints.getAdminSalesAvarageDatas, data)

//Admin > Reports > Cost
export const getAdminCostGraphService = (data) => authAxios.post(EndPoints.getAdminCostGraph, data)
export const getAdminCostReportService = (data, signal) =>
    authAxios.post(EndPoints.getAdminCostReport, data, {signal})
export const getParentChildCostCenterService = () =>
    authAxios.get(EndPoints.getParentChildCostCenter)
export const exportCostDataService = (data) => authAxios.post(EndPoints.exportCostData, data)

//Admin > Reports > Clawback
export const getAdminClawBackReportService = (data, signal) =>
    authAxios.post(EndPoints.getAdminClawBackReport, data, {signal})

export const exportAdminClawBackReportService = (data) =>
    authAxios.post(EndPoints.exportAdminClawBackReport, data)

//Admin > Reports > Payroll
export const getAdminPayrollReportV1Service = (data, signal) =>
    authAxios.post(EndPoints.getAdminPayrollReportV1, data, {signal})
export const exportPayrollReportService = (data) =>
    authAxios.post(EndPoints.exportPayrollReport, data)
export const getAdminPayrollReportByFiltersV1Service = (data) =>
    authAxios.post(EndPoints.getAdminPayrollByFiltersV1, data)
export const getAdminPayrollReportByYearMonthAndFrequencyService = (data) =>
    authAxios.post(EndPoints.getAdminPayrollReportByYearMonthAndFrequency, data)

// Payroll Reconciliation office dropdown
export const getReconOfficeDropdownListService = () =>
    authAxios.get(EndPoints.getReconOfficeDropdownList)
export const getReconPositionDropdownListService = () =>
    authAxios.get(EndPoints.getReconPositionDropdownList)
export const getReconciliationFinalizeDraftListV1 = (params) =>
    authAxios.get(EndPoints.reconciliationFinalizeDraftListV1, {params})
export const getSkippedReconListV1Service = (data) =>
    authAxios.post(EndPoints.skippedReconListV1, data)
export const getUndoSkippedReconListV1Service = (data) =>
    authAxios.post(EndPoints.undoSkippedReconListV1, data)
export const getReconAdjustmentDataV1Service = (data) =>
    authAxios.post(EndPoints.getReconAdjustmentDataV1, data)
export const getReconAdminReportAdjustmentDataV1Service = (data) =>
    authAxios.post(EndPoints.getReconAdminReportAdjustmentDataV1, data)

//Admin > Reports > Pending Installs
export const getAdminPendingInsatllReportService = (data, signal) =>
    authAxios.post(EndPoints.getAdminPendingInstallReport, data, {signal})

export const exportAdminPendingInstallReportService = (data) =>
    authAxios.post(EndPoints.exportAdminPendingInstallReport, data)

//Admin > Reports > Company
export const getAdminCompanyReportService = (data) =>
    authAxios.post(EndPoints.getAdminCompanyReport, data)
export const getAdminCompanyGraphService = (data) =>
    authAxios.post(EndPoints.getAdminCompanyGraph, data)

//Admin > Permission > Group
export const getPermissionGroupListService = (params) =>
    authAxios.get(EndPoints.getGroupList, {params})
export const getPermissionPoliciesListService = (params, signal) =>
    authAxios.get(EndPoints.getPoliciesList, {params, signal})
export const deletePermissionService = (id) => authAxios.get(`${EndPoints.deletePermission}/${id}`)
export const getPermissionService = (id) => authAxios.get(`${EndPoints.getPermission}/${id}`)
export const addAdminGroupPermissionService = (data) =>
    authAxios.post(EndPoints.addGroupPermission, data)
export const updateAdminGroupPermissionService = (data) =>
    authAxios.post(EndPoints.updateGroupPermission, data)
export const groupGroupPolicyListService = (id) => authAxios.get(EndPoints.groupPolicyList)

export const getListofGroupService = (params) => authAxios.get(EndPoints.getListofGroup, {params})

//Sm > My Sales > My Sales
export const getMySalesGraphService = (params) =>
    authAxios.post(EndPoints.getMySalesGraph, null, {params})
export const getMySalesAccountInitialRatioGraphV1Service = (data) =>
    authAxios.post(EndPoints.getMySalesAccountInitialRatioGraphV1, data)
export const getMySalesListV1Service = (data, signal) =>
    authAxios.post(EndPoints.getMySalesListV1, data, {signal})

//Closer and Setter
export const getSalesCloserListService = () => authAxios.get(EndPoints.getSalesCloserList)
export const getSalesSetterListService = () => authAxios.get(EndPoints.getSalesSetterList)
export const getSalesByPidService = (data) => authAxios.post(EndPoints.getSalesByPid, data)
export const updateCloserByPidService = (data) => authAxios.post(EndPoints.updateCloserByPid, data)
export const getCloserSetterByEffectiveDate = (effective_date, user_type) =>
    authAxios.post(EndPoints.getCloserSetterByEffectiveDate, {
        effective_date: getValidDate(effective_date, 'YYYY-MM-DD'),
        user_type,
    })

export const updateSetterByPidService = (data) => authAxios.post(EndPoints.updateSetterByPid, data)
export const getMySalesOverrideDataService = (params) =>
    authAxios.get(EndPoints.getMySalesOverrideData, {params})
export const getMyRecalculateDataService = (data) =>
    authAxios.post(EndPoints.getMyRecalculateData, data)

export const getSaleUserRedlinesService = (id) =>
    authAxios.get(`${EndPoints.getSaleUserRedlines}/${id}`)

export const getGeneralCodeByStateIdService = (stateId) =>
    authAxios.get(`${EndPoints.getGeneralCodeByStateId}/${stateId}`)

//Admin > Alert Center
export const getAlertCenterListService = (params, signal) =>
    authAxios.get(EndPoints.getAlertCenterList, {params, signal})
export const getMissingDataAlertCenterService = (id) =>
    authAxios.get(`${EndPoints.getMissingDataAlertCenter}/${id}`)
export const updateMissingDataAlertCenterService = (data) =>
    authAxios.post(EndPoints.updateMissingDataAlertCenter, data)

export const updateMissingPayPeriodService = (data) =>
    authAxios.post(EndPoints.updateMissingPayPeriod, data)

export const getAdderByPidAlertCenterService = (id) =>
    authAxios.get(`${EndPoints.getAdderByPidAlertCenter}/${id}`)
export const getClawbackByPidAlertCenterService = (id) =>
    authAxios.get(`${EndPoints.getClawbackByPidAlertCenter}/${id}`)
export const getPayrollByidAlertCenterService = (body) =>
    authAxios.post(EndPoints.getPayrollByidAlertCenter, body)

export const getPayRollMissingDetailsService = (id) =>
    authAxios.get(`${EndPoints.getPayRollMissingDetails}/${id}`)

export const getAllAlertMissingDataService = (id) =>
    authAxios.get(`${EndPoints.getAllAlertMissingData}/${id}`)
export const getSalesAndMissingRepMissingDetailService = (id) =>
    authAxios.get(`${EndPoints.getSalesAndMissingRepMissingDetail}/${id}`)

export const searchAllAlertDataService = (body, signal) =>
    authAxios.post(EndPoints.searchAllAlertData, body, {signal})

export const refreshAlertCenterDetailsService = (body) =>
    authAxios.post(EndPoints.refreshAlertCenterDetails, body)

// Admin > Settings > Position Update
export const updatePositionHierarchyService = (data) =>
    authAxios.post(EndPoints.updatePositionHierarchy, data)
export const updatePositionGroupService = (position_id, data) =>
    authAxios.put(`${EndPoints.updatePositionGroup}/${position_id}`, data)
export const positionOrgChartService = (signal) =>
    authAxios.get(EndPoints.positionOrgChart, {signal})

//Standard > Calendar > Calander Event.
export const addCalanderEventService = (data) => authAxios.post(EndPoints.addCalanderEvent, data)
export const getCalanderEventListService = (data) =>
    authAxios.post(EndPoints.getCalanderEventList, data)

export const deleteCalanderEventService = (id) =>
    authAxios.delete(`${EndPoints.deleteCalanderEvent}/${id}`)

export const updateCalanderEventService = (id, data) =>
    authAxios.put(`${EndPoints.updateCalanderEvent}/${id}`, data)

export const getGlobalSheduleTimeCalendarService = (id, data) =>
    authAxios.get(EndPoints.getGlobalSheduleTimeCalendar)

export const setSheduleTimeSlotService = (data) =>
    authAxios.post(EndPoints.setSheduleTimeSlot, data)

export const getUserSheduleTimeService = (id) =>
    authAxios.get(`${EndPoints.getUserSheduleTime}/${id}`)

//Standard > Reports > Sales > Account Overrides
export const getAccountOverridesbyPidService = (Pid) =>
    authAxios.get(`${EndPoints.getAccountOverridesbyPid}/${Pid}`)

//Sm > management > Teams
export const getManagmentTeamsService = (params, signal) =>
    authAxios.get(EndPoints.getManagmentTeams, {params, signal})

export const getTeamLeadByOfficeIdService = (id) =>
    new Promise((resolve, reject) => {
        authAxios
            .get(`${EndPoints.getTeamLeadByOfficeId}/${id}`)
            .then((res) => {
                const response = getActiveUserFromResponse(res)
                resolve(response)
            })
            .catch(reject)
    })
export const getTeamMembersByOfficeIdService = (id) =>
    new Promise((resolve, reject) => {
        authAxios
            .get(`${EndPoints.getTeamMembersByOfficeId}/${id}`)
            .then((res) => {
                const response = getActiveUserFromResponse(res)
                resolve(response)
            })
            .catch(reject)
    })

export const deleteManagmentTeamMemberService = (data) =>
    authAxios.post(EndPoints.deleteManagmentTeamMember, data)
export const deleteTeamService = (id) => authAxios.get(`${EndPoints.deleteTeam}/${id}`)
export const addManagementTeamService = (data) => authAxios.post(EndPoints.addManagementTeam, data)
export const updateManagementTeamService = (data) =>
    authAxios.put(EndPoints.updateManagementTeam, data)

// Admin > Reports > Sales > Add Sales
export const addSaleService = (data) => authAxios.post(EndPoints.addSale, data)
export const salesAccountSummaryService = (data) =>
    authAxios.post(EndPoints.salesAccountSummaryData, jsonToFormData(data))

export const salesAccountSummarybyPositionService = (data) =>
    authAxios.post(EndPoints.salesAccountSummarybyPosition, jsonToFormData(data))

export const getCompanyAccountSummaryService = (data) =>
    authAxios.post(EndPoints.getCompanyAccountSummary, jsonToFormData(data))

export const getRedlinesUserWiseService = (data) =>
    authAxios.post(EndPoints.getSaleRedlinesUserWise, data)

export const getSaleRedlinesFromPIDService = (pid) =>
    authAxios.get(`${EndPoints.getSaleRedlinesFromPID}/${pid}`)

export const getProjectionSummaryV1Service = (body) =>
    authAxios.post(EndPoints.getProjectionSummaryV1, body)

// Get Sale Data For Super Admin
export const getSaleDataForSuperAdminService = (pid, password) =>
    authAxios.post(EndPoints.getSaleDataForSuperAdmin, {
        pid,
        password,
    })

//Admin > Payroll > Run Payroll
export const getPayrollDataV1Service = (data, signal) =>
    authAxios.post(EndPoints.getPayrollDataV1, data, {signal})
export const getPayrollHistoryService = (data) => authAxios.post(EndPoints.getPayrollHistory, data)
export const getPayrollHistoryV1Service = (data) =>
    authAxios.post(EndPoints.getPayrollHistoryV1, data)
export const getReportPayrollHistoryService = (data) =>
    authAxios.post(EndPoints.getReportPayrollHistory, data)
export const payrollMarkAsPaidV1Service = (data) =>
    authAxios.post(EndPoints.payrollMarkAsPaidV1, data)
export const singleMoveToNextPayrollV1Service = (data) =>
    authAxios.post(EndPoints.singleMoveToNextPayrollV1, data)
export const singleMoveToReconV1Service = (data) =>
    authAxios.post(EndPoints.singleMoveToReconV1, data)
export const singleAdjustmentDeleteV1Service = (data) =>
    authAxios.post(EndPoints.singleAdjustmentDeleteV1, data)
export const undoPayrollApprovalRequestV1Service = (data) =>
    authAxios.post(EndPoints.undoPayrollApprovalRequestV1, data)

//Admin > Payroll > Reconciliation
export const getPayrollReconDetailV1Service = (body, signal) =>
    authAxios.post(EndPoints.payrollReconciliationV1, body, {signal})

export const getCommissionDetailsV1Service = (body) =>
    authAxios.post(EndPoints.getCommissionDetailsV1, body)

export const getReportCommissionDetailsV1Service = (body) =>
    authAxios.post(EndPoints.getReportCommissionDetailsV1, body)

export const getPaystubCommissionDetailsService = (body) =>
    authAxios.post(EndPoints.getPaystubCommissionDetails, body)

export const getReportReconciliationDetailsService = (body) =>
    authAxios.post(EndPoints.getReportReconciliationDetails, body)

export const getPaystubReconReportService = (body) =>
    authAxios.post(EndPoints.getPaystubReconReport, body)

export const getOneTimePaymentReimbursementDetailsService = (body) =>
    authAxios.post(EndPoints.getOneTimePaymentReimbursementDetails, body)
export const getOneTimePaymentAdjustmentDetailsService = (body) =>
    authAxios.post(EndPoints.getOneTimePaymentAdjustmentDetails, body)
export const getReimbursementDetailsV1Service = (body) =>
    authAxios.post(EndPoints.getReimbursementDetailsV1, body)
export const getReportReimbursementDetailsV1Service = (body) =>
    authAxios.post(EndPoints.getReportReimbursementDetailsV1, body)
export const getPaystubReimbursementDetailsService = (body) =>
    authAxios.post(EndPoints.getPaystubReimbursementDetails, body)

export const getOverrideDetailsV1Service = (body) =>
    authAxios.post(EndPoints.getOverrideDetailsV1, body)
export const getReportOverrideDetailsV1Service = (body) =>
    authAxios.post(EndPoints.getReportOverrideDetailsV1, body)
export const getPaystubOverrideDetailsService = (body) =>
    authAxios.post(EndPoints.getPaystubOverrideDetails, body)

export const getAdjustmentDetailsV1Service = (body) =>
    authAxios.post(EndPoints.getAdjustmentDetailsV1, body)
export const getReportAdjustmentDetailsV1Service = (body) =>
    authAxios.post(EndPoints.getReportAdjustmentDetailsV1, body)
export const getPaystubAdjustmentDetailsService = (body) =>
    authAxios.post(EndPoints.getPaystubAdjustmentDetails, body)

export const editOverrideDetailsService = (body) =>
    authAxios.post(EndPoints.editOverrideDetails, body)
export const editCommissionDetailsService = (body) =>
    authAxios.post(EndPoints.editCommissionDetails, body)
export const editReconCommissionDetailsService = (body) =>
    authAxios.post(EndPoints.editReconCommissionDetails, body)
export const editReconOverrideDetailsService = (body) =>
    authAxios.post(EndPoints.editReconOverrideDetails, body)
export const editDeductionDetailsService = (body) =>
    authAxios.post(EndPoints.editDeductionDetails, body)

export const deleteAdjustmentDetailsService = (body) =>
    authAxios.post(EndPoints.deleteAdjustmentDetails, body)
export const deleteAdjustmentReconDetailsService = (body) =>
    authAxios.post(EndPoints.deleteAdjustmentReconDetails, body)
export const getPaymentRequestDetailsService = (params, signal) =>
    authAxios.get(EndPoints.getPaymentRequestDetails, {params, signal})
export const payNowPaymentRequestService = (body) =>
    authAxios.post(EndPoints.payNowPaymentRequest, body)
export const getAdvancePaymentRequestDetailsService = (params) =>
    authAxios.get(EndPoints.getAdvancePaymentRequestDetails, {params})
export const updatePaymentRequestV1Service = (data) =>
    authAxios.post(EndPoints.updatePaymentRequestV1, data)
export const getReconciliationDetailService = (data) =>
    authAxios.post(EndPoints.getReconciliationDetail, data)
export const getPayrollDeductionDetailService = (data) =>
    authAxios.post(EndPoints.getPayrollDeductionDetail, data)
export const getPaystubDeductionDetailService = (data) =>
    authAxios.post(EndPoints.getPaystubDeductionDetail, data)

export const updateReconciliationDetailService = (data) =>
    authAxios.post(EndPoints.updateReconciliationDetail, data)
export const payrollReconciliationHistoryService = (data) =>
    authAxios.post(EndPoints.payrollReconciliationHistory, data)

export const updatePayrollDetailService = (data) =>
    authAxios.post(EndPoints.updatePayrollDetail, data)
export const adjustPayrollDetailService = (data) =>
    authAxios.post(EndPoints.adjustPayrollDetail, data)
export const oneTimePaymentService = (data) => authAxios.post(EndPoints.oneTimePayment, data)
export const oneTimePaymentHistoryService = (data) =>
    authAxios.post(EndPoints.oneTimePaymentHistory, data)
export const oneTimePaymentTotalService = (id) =>
    authAxios.get(EndPoints.onTimePaymentTotal, {
        params: {
            id: id,
        },
    })
export const exportOneTimePaymentHistoryService = (params) =>
    authAxios.get(EndPoints.exportOneTimePaymentHistory, {params})
export const finilizeReconciliationService = (data, signal) =>
    authAxios.post(EndPoints.finilizeReconciliation, data, {signal})
export const addToPayrollReconciliationV1Service = (data) =>
    authAxios.post(EndPoints.sendToPayrollReconV1, data)
export const getFinilizePayrollService = (data) => authAxios.get(EndPoints.getFinilizePayroll)
export const getFinalizePayrollStatusService = (start_date, end_date) =>
    authAxios.post(EndPoints.getFinalizePayrollStatus, {start_date, end_date})
export const finilizePayrollV1Service = (data) => authAxios.post(EndPoints.finilizePayrollV1, data)
export const executePayrollV1Service = (data) => authAxios.post(EndPoints.executePayrollV1, data)
export const moveToNextPayrollV1Service = (data) =>
    authAxios.post(EndPoints.moveToNextPayrollV1, data)
export const markAsPaidPayrollV1Service = (data) =>
    authAxios.post(EndPoints.markAsPaidPayrollV1, data)
export const moveToReconciliationPayrollV1Service = (data) =>
    authAxios.post(EndPoints.moveToReconciliationPayrollV1, data)
export const payrollReconClawbackPopUpService = (id, params) =>
    authAxios.get(`${EndPoints.payrollReconClawbackPopUp}/${id}`, {params})
export const payrollReconAdjustmentPopUpService = (id, params) =>
    authAxios.get(`${EndPoints.payrollReconAdjustmentPopUp}/${id}`, {params})
export const payrollReconOverridePopUpService = (id, params) =>
    authAxios.get(`${EndPoints.payrollReconOverridePopUp}/${id}`, {params})
export const payrollReconCommissionPopUpService = (id, params) =>
    authAxios.get(`${EndPoints.payrollReconCommissionPopUp}/${id}`, {params})
export const reportReconOverridePopUpService = (id, params) =>
    authAxios.get(`${EndPoints.reportReconOverridePopUp}/${id}`, {params})
export const reportReconAdjustmentPopUpService = (id, params) =>
    authAxios.get(`${EndPoints.reportReconAdjustmentPopUp}/${id}`, {params})
export const reportReconClawbackPopUpService = (id, params) =>
    authAxios.get(`${EndPoints.reportReconClawbackPopUp}/${id}`, {params})
export const reportReconCommissionPopUpService = (id, params) =>
    authAxios.get(`${EndPoints.reportReconCommissionPopUp}/${id}`, {params})
export const closePayrollService = (data) => authAxios.post(EndPoints.closePayroll, data)
export const undoReconPayrollService = (data) => authAxios.post(EndPoints.undoReconPayroll, data)
export const undoPaidPayrollV1Service = (data) => authAxios.post(EndPoints.undoPaidPayrollV1, data)
export const undoMoveToNextPayrollV1Service = (data) =>
    authAxios.post(EndPoints.undoMoveToNextPayrollV1, data)
export const editReconClawBackAdjustmentV1Service = (data) =>
    authAxios.post(EndPoints.editReconClawBackAdjustmentV1, data)

export const particularUserReonAccountSummaryOnPidV1Service = (id, body) =>
    authAxios.post(`${EndPoints.particularUserReonAccountSummaryOnPidV1}/${id}`, body)

export const particularUserReonAccountSummaryReportV1Service = (body) =>
    authAxios.post(`${EndPoints.particularUserReonAccountSummaryReportV1}`, body)

//payroll export

export const exportWorkerBasicPayrollDetailService = (data) =>
    authAxios.post(EndPoints.exportWorkerBasicPayrollDetail, data)

export const exportWorkerDetailsPayrollDetailService = (data) =>
    authAxios.post(EndPoints.exportWorkerDetailsPayrollDetail, data)

export const exportWorkerAllDetailsPayrollDetailService = (data) =>
    authAxios.post(EndPoints.exportWorkerAllDetailsPayrollDetail, data)

export const exportPidDetailsPayrollDetailService = (data) =>
    authAxios.post(EndPoints.exportPidDetailsPayrollDetail, data)

export const exportPidBasicPayrollDetailService = (data) =>
    authAxios.post(EndPoints.exportPidBasicPayrollDetail, data)

//report export

export const exportWorkerBasicReportsDetailService = (data) =>
    authAxios.post(EndPoints.exportWorkerBasicReportsDetail, data)

export const exportWorkerDetailsReportsDetailService = (data) =>
    authAxios.post(EndPoints.exportWorkerDetailsReportsDetail, data)

export const exportWorkerAllDetailsReportsDetailService = (data) =>
    authAxios.post(EndPoints.exportWorkerAllDetailsReportsDetail, data)

export const exportPidDetailsReportsDetailService = (data) =>
    authAxios.post(EndPoints.exportPidDetailsReportsDetail, data)

export const exportPidBasicReportsDetailService = (data) =>
    authAxios.post(EndPoints.exportPidBasicReportsDetail, data)

//Onboarding Employees>After loign
export const getOnBoardingEmployeeDetailService = (id) =>
    authAxios.get(`${EndPoints.getOnBoardingEmployeeDetail}/${id}`)
export const updateOnBoardingEmployeeDetailService = (data) =>
    authAxios.post(EndPoints.updateOnBoardingEmployeeDetail, jsonToFormData(data))

export const changeOnboardingEmployeeStatusService = (employee_id, status_id) =>
    authAxios.post(EndPoints.changeOnboardingEmployeeStatus, {
        onboardingEmployee_id: employee_id,
        status_id,
    })

export const changeOnboardingEmployeeAgreementStatusService = (employee_id, status) =>
    authAxios.post(EndPoints.changeOnboardingEmployeeAgreementStatus, {
        user_id: employee_id,
        is_agreement_accepted: status,
    })
export const uploadOnBoardingDocumentService = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.uploadOnBoardingDocument, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })

export const deleteOnBoardingDocumentService = (id) =>
    authAxios.delete(`${EndPoints.deleteOnBoardingDocument}/${id}`)

export const getPayFrequencySettingService = () => authAxios.get(EndPoints.getPayFrequencySetting)
// Pay Periods
// Weekly Pay Period
export const getWeeklyPayPeriodService = () => authAxios.get(EndPoints.getWeeklyPayPeriod)
export const getWeeklyExecutedPayPeriodService = () =>
    authAxios.get(EndPoints.getWeeklyExecutedPayPeriod)

// Monthly Pay Period
export const getMonthlyPayPeriodService = () => authAxios.get(EndPoints.getMonthlyPayPeriod)
export const getMonthlyExecutedPayPeriodService = () =>
    authAxios.get(EndPoints.getMonthlyExecutedPayPeriod)

// Bi-Weekly Pay Period
export const getBiWeeklyPayPeriodService = () => authAxios.get(EndPoints.getBiWeeklyPayPeriod)
export const getBiWeeklyExecutedPayPeriodService = () =>
    authAxios.get(EndPoints.getBiWeeklyExecutedPayPeriod)

// Semi-Monthly Pay Period
export const getSemiMonthlyPayPeriodService = () => authAxios.get(EndPoints.getSemiMonthlyPayPeriod)
export const getSemiMonthlyExecutedPayPeriodService = () =>
    authAxios.get(EndPoints.getSemiMonthlyExecutedPayPeriod)

export const addPayFrequencySettingService = (body) =>
    authAxios.post(EndPoints.addPayFrequencySetting, body)

//New Employment Package
export const getUserEmploymentPackageDetailService = (id) =>
    authAxios.get(`${EndPoints.getuserEmploymentPackageByid}/${id}`)
export const updateUserPersonalDetailService = (body) =>
    authAxios.post(EndPoints.updateUserPersonalData, body)
export const updateUserOrganizationDetailService = (body) =>
    authAxios.post(EndPoints.updateUserOrganizationData, body)
export const updateUserDeductionDetailService = (body) =>
    authAxios.post(EndPoints.updateUserDeductionData, body)
export const updateUserOverrideDetailService = (body) =>
    authAxios.post(EndPoints.updateUserOverrideData, body)
export const updateUserAgreementDetailService = (body) =>
    authAxios.post(EndPoints.updateUserAgreementData, body)
export const updateUserCompensationDetailService = (body) =>
    authAxios.post(EndPoints.updateUserCompensation, body)

export const getUserEmploymentOrganizationHistoryService = (body) =>
    authAxios.post(EndPoints.getOrganizationDetail, body)
export const getTransferHistoryService = (body) =>
    authAxios.post(EndPoints.getTransferHistory, body)
export const getOverrideHistoryService = (body) =>
    authAxios.post(EndPoints.getOverrideHistory, body)
export const getRedlineHistroyService = (body) =>
    authAxios.post(EndPoints.getRedlineUpfrontHistory, body)
export const getDeductionHistroyService = (body) =>
    authAxios.post(EndPoints.getDeductionHistory, body)

//Search API
export const officeBYUserIDSerices = (id) =>
    new Promise((resolve, reject) => {
        authAxios
            .get(`${EndPoints.userByOfficeId}/${id}`)
            .then((res) => {
                let response = getActiveUserFromResponse(res)
                resolve(response)
            })
            .catch(reject)
    })

//Admin Dashboard API
export const getDashboardPayrollSummaryService = (body) =>
    authAxios.post(EndPoints.getDashboardPayrollSummary, body)
export const getTopPayrollLocationsService = (filter) =>
    authAxios.post(EndPoints.getTopPayrollLocations, {filter})
export const getAnnouncementListService = (params, signal) =>
    authAxios.get(EndPoints.getAnnouncementList, {params, signal})
export const getAnnouncementCardDataService = () => authAxios.get(EndPoints.getAnnouncementCardData)
export const getDashboardAlertListService = () => authAxios.get(EndPoints.getAlertList)
export const getDashboardHiringAlertListService = (id) =>
    authAxios.get(`${EndPoints.getHiringAlertList}/${id}`)

export const getOfficeSalesPerformanceDataService = (body) =>
    authAxios.post(EndPoints.getDashboardOfficeSalesPerformance, body)
export const getOfficeSalesPerformanceGraphService = (body) =>
    authAxios.post(EndPoints.getOfficeSalesPerformanceGraph, body)

export const getSmDashboardSalesReportService = (body) =>
    authAxios.post(EndPoints.getSmDashboardSalesReport, body)

// Standard dashboard
export const getDashboardActionItemsListService = () =>
    authAxios.get(EndPoints.getDashboardActionItemsList)

export const dashboardActionItemStatusChangeService = (body) =>
    authAxios.post(EndPoints.dashboardActionItemStatusChange, body)

export const getSmOfficeSalesPerformanceService = (body) =>
    authAxios.post(EndPoints.getSmOfficeSalesPerformance, body)

export const getSmOfficePeformanceTeamService = (body) =>
    authAxios.post(EndPoints.getSmOfficePeformanceTeam, body)

export const getGoalTrackerDataService = (body) =>
    authAxios.post(EndPoints.getGoalTrackerData, body)
export const getSmOfficeEventListService = () => authAxios.post(EndPoints.getSmOfficeEventList)
export const setGoalTrackerService = (body) => authAxios.post(EndPoints.setGoalTracker, body)

export const addAnnouncementDataService = (data) => authAxios.post(EndPoints.addAnnouncement, data)
export const disableAnnouncementService = (data) =>
    authAxios.post(EndPoints.disableAnnouncement, data)
export const deleteAnnouncementService = (id) =>
    authAxios.post(`${EndPoints.deleteAnnouncement}?id=${id}`)

//Past paystub
export const getPastPaystubGraphDataService = (body) =>
    authAxios.post(EndPoints.getPastPaystubGraphData, body)
export const getPastPaystubTableDataService = (body, signal) =>
    authAxios.post(EndPoints.getPastPaystubTableData, body, {signal})
export const getPastPaystubDetailService = (body) =>
    authAxios.post(EndPoints.getPastPaystubDetail, body)

export const getPastPaystubDetailListService = (body) =>
    authAxios.post(EndPoints.getPastPaystubDetailList, body)
export const getPastPaystubDetailListV1Service = (body) =>
    authAxios.post(EndPoints.getPastPaystubDetailListV1, body)
export const getCurrentPaystubDetailService = (body) =>
    authAxios.post(EndPoints.getCurrentPaystubDetail, body)
export const getCurrentPaystubCustomerDetailService = (body) =>
    authAxios.post(EndPoints.getCurrentPaystubCustomerDetail, body)

export const getPastPaystubUserDetailService = (body) =>
    authAxios.post(EndPoints.getPastPaystubUserDetail, body)
export const getOneTimePaymentUserDetailService = (body, signal) =>
    authAxios.post(EndPoints.getOneTimePaymentUserDetail, body, {signal})
export const getSingleOneTimePaymentUserDetailService = (body) =>
    authAxios.post(EndPoints.getSingleOneTimePaymentUserDetail, body)

//Filters
export const LocationFilterService = (body) => authAxios.post(EndPoints.locationFilter, body)
export const positionFilterService = (params) => authAxios.get(EndPoints.positionFilter, {params})
export const leadsFilterService = (params) => authAxios.get(EndPoints.leadsFilter, {params})
export const getAllReportingManagerListService = () =>
    authAxios.get(EndPoints.getAllReportingManagerList)
export const runPayrollFilterService = (body) => authAxios.post(EndPoints.runPayrollFilter, body)
export const salesReportFilterService = (body) => authAxios.post(EndPoints.salesReportFilter, body)

//Employment Package History
export const getEmploymentPackageHistoryService = (body) =>
    authAxios.post(EndPoints.getEmploymentPackageHistory, body)
export const deleteEmploymentPackageHistoryService = (body) =>
    authAxios.post(EndPoints.deleteEmploymentPackageHistory, body)
export const deleteManualOverrideHistoryService = (body) =>
    authAxios.post(`${EndPoints.deleteManualOverrideHistory}`, body)

//Setting UserManagement
export const getUserManagementListService = (params, signal) =>
    authAxios.post(EndPoints.getUserManagementList, params, {signal})
export const addNewAdminService = (body) => authAxios.post(EndPoints.addNewAdmin, body)
export const makeSuperAdminService = (user_id, is_super_admin) =>
    authAxios.put(EndPoints.makeSuperAdmin, {user_id, is_super_admin})
export const suspendAccessOfUserService = (user_id) =>
    authAxios.put(EndPoints.suspendAccessOfUser, {user_id})
export const exportUserManagementListService = (params) =>
    authAxios.get(EndPoints.exportUserManagementList, {params, responseType: 'arraybuffer'})

//Setting Billing Adderess
export const getOverdueBillingStatusService = () =>
    authAxios.post(EndPoints.getOverdueBillingStatus)
export const getBusinessAddressListService = () => authAxios.get(EndPoints.getBusinessAddressList)
export const updateBusinessAddressListService = (body) =>
    authAxios.post(EndPoints.updateBusinessAddress, body)
export const manageSubscriptionService = (body) =>
    authAxios.post(EndPoints.manageSubscription, body)

export const getSubscriptionListService = (body) =>
    authAxios.post(EndPoints.getSubscriptionList, body)

export const getBillingHistoryDataService = (data) =>
    authAxios.post(EndPoints.getBillingHistoryData, data)

export const getSubscriptionPidDataService = (body, signal) =>
    authAxios.post(EndPoints.getSubscriptionPidData, body, {signal})

export const getBillingPidDataService = (body, signal) =>
    authAxios.post(EndPoints.getBillingPidData, body, {signal})

export const getBillingM2DataService = (body, signal) =>
    authAxios.post(EndPoints.getBillingM2Data, body, {signal})

export const getBillingAdjustedPidDataService = (body, signal) =>
    authAxios.post(EndPoints.getBillingAdjustedPidData, body, {signal})

export const getSubscriptionM2CompleteDataService = (body, signal) =>
    authAxios.post(EndPoints.getSubscriptionM2CompleteData, body, {signal})

export const getSubscriptionAdjustedPidDataService = (body, signal) =>
    authAxios.post(EndPoints.getSubscriptionAdjustedPidData, body, {signal})

export const getSubscriptionPayrollChecksService = (body, signal) =>
    authAxios.post(EndPoints.getSubscriptionPayrollChecks, body, {signal})

export const getBillingPayrollChecksService = (body, signal) =>
    authAxios.post(EndPoints.getBillingPayrollChecks, body, {signal})

export const getSubscriptionOneTimePaymentService = (body, signal) =>
    authAxios.post(EndPoints.getSubscriptionOneTimePayment, body, {signal})

export const getActiveUserBillingService = (body, signal) =>
    authAxios.post(EndPoints.getActiveUserBilling, body, {signal})

export const getBillingPaymentGatewayInfoService = (body) =>
    authAxios.post(EndPoints.getBillingPaymentGatewayInfo, body)

export const paymentCallbackService = (body) => authAxios.post(EndPoints.paymentCallback, body)

export const getStripePaymentMethodsService = () => authAxios.get(EndPoints.getStripePaymentMethods)

export const updateStripeDefaultPaymentMethodService = (payment_method_id) =>
    authAxios.post(EndPoints.updateStripeDefaultPaymentMethod, {payment_method: payment_method_id})

export const updateAutoPayPaymentSettingService = (auto_pay) =>
    authAxios.post(EndPoints.updateAutoPayPaymentSetting, {stripe_autopayment: auto_pay})

export const deleteStripePaymentMethodService = (payment_method_id) =>
    authAxios.post(EndPoints.deleteStripePaymentMethod, {payment_method: payment_method_id})

export const getClientIntentService = () => authAxios.get(EndPoints.getClientIntent)

export const getBillingInvoiceService = (id) =>
    authAxios.get(`${EndPoints.getBillingInvoice}/${id}`)
export const getBillingOneTimePaymentDataService = (body, signal) =>
    authAxios.post(EndPoints.getInvoiceOneTimePaymentData, body, {signal})

export const importAdminSalesDataService = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.importAdminSalesData, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })

export const importAdminSalesHistoryDataService = () =>
    authAxios.get(EndPoints.importAdminSalesHistoryData)

// Import User
export const importOnboardingUsersDataService = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.importOnboardingUsersSalesData, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })

export const importOnboardingUsersHistoryDataService = () =>
    authAxios.get(EndPoints.importOnboardingUsersHistoryData)

export const downloadRequiredFilesForImportOnboardingUsersService = () =>
    authAxios.get(EndPoints.downloadRequiredFilesForImportOnboardingUsers, {
        responseType: 'blob',
    })

export const adminSalesImportValidationCheckService = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.adminSalesImportValidationCheck, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })

//Setting>Email Setting
export const emailSettingService = (body) => authAxios.post(EndPoints.emailSetting, body)
export const testEmailSettingService = (body) => authAxios.post(EndPoints.testEmailSetting, body)
export const getSettingService = () => authAxios.get(EndPoints.getEmailSetting)

// Additional Emails
export const getUserAdditionalEmailsService = (userId) =>
    authAxios.get(`${EndPoints.getUserAdditionalEmails}/${userId}`)

export const deleteUserAdditionalEmailService = (userId) =>
    authAxios.delete(`${EndPoints.deleteUserAdditionalEmail}/${userId}`)

export const addUpdateUserAdditionalEmailService = (user_id, emails) =>
    authAxios.post(EndPoints.addUpdateUserAdditionalEmail, {user_id, emails})

// Import Excel Templates
export const getImportExcelTemplateListService = () =>
    authAxios.post(EndPoints.getImportExcelTemplateList)
export const saveImportExcelTemplateService = (data) =>
    authAxios.post(EndPoints.saveImportExcelTemplate, data)
export const deleteImportExcelTemplateService = (id) =>
    authAxios.post(EndPoints.deleteImportExcelTemplate, {id})

// Ticket System Module
export const getTicketListService = (
    priority = '',
    ticket_status = '',
    search = '',
    page = 1,
    limit = 10,
    module = '',
    created_by = '',
    signal
) =>
    authAxios.get(
        `${EndPoints.getTicketList}?priority=${priority}&ticket_status=${ticket_status}&search=${search}&page=${page}&per_page=${limit}&module=${module}&created_by=${created_by}`,
        {signal}
    )
export const deleteTicketbyIdService = (ticket_id) =>
    authAxios.post(`${EndPoints.deleteTicketbyId}/${ticket_id}`)

export const getParticularTicketbyIdService = (ticket_id) =>
    authAxios.get(`${EndPoints.getParticularTicketbyId}/${ticket_id}`)

export const createTicketService = (body) => authAxios.post(EndPoints.createTicket, body)

export const getModuleDropdownListService = () =>
    authAxios.get(`${EndPoints.getModuleDropdownList}`)

export const getFaQListService = (search = '', status = '') =>
    authAxios.get(`${EndPoints.getFaQList}?search=${search}&status=${status}`)

export const getTopTopicListService = (type) =>
    authAxios.get(`${EndPoints.getTopTopicList}?type=${type}`)

export const getParticularTopicDataService = (id = '') =>
    authAxios.get(`${EndPoints.getParticularTopicData}?id=${id}`)

export const getTopicSearchSuggestionsService = (search = '', type = '') =>
    authAxios.get(`${EndPoints.getTopicSearchSuggestions}?search=${search}&type=${type}`)

//New sequidoc flow
export const getNewSequiDocsMainTemplatesService = () =>
    authAxios.get(EndPoints.getNewSequiDocsMainTemplates)

export const getNewParticularTemplatesListService = (id, params, signal) =>
    authAxios.get(`${EndPoints.getNewParticularTemplateList}/${id}`, {params, signal})

export const getUnAssignedOfferLetterListService = (id, signal) =>
    authAxios.get(EndPoints.getUnAssignedOfferLetterList, {signal})

export const addNewTemplateSettingStep1Service = (data) =>
    authAxios.post(EndPoints.addNewTemplateSettingStep1, data)

export const checkOfferLetterDuplicateRecipientService = (data) =>
    authAxios.post(EndPoints.checkOfferLetterDuplicateRecipient, data)

export const newSequiDocTemplateDetailByIdService = (id) =>
    authAxios.get(`${EndPoints.newSequiDocTemplateDetailById}/${id}`)

export const newSequiDocUpdateTemplateService = (data) =>
    authAxios.post(EndPoints.newSequiDocUpdateTemplate, data)

export const newSequiDocEditTemplateStep2Service = (data) =>
    authAxios.post(EndPoints.newSequiDocEditTemplateStep2, data)

export const newSequiDocAgreementListService = () =>
    authAxios.get(EndPoints.newSequiDocAgreementList)

export const newSequiDocAdditionalDocumentListService = () =>
    authAxios.get(EndPoints.newSequiDocAdditionalDocumentList)

export const newSequiDocForHiringStep3Service = (data) =>
    authAxios.post(EndPoints.newSequiDocForHiringStep3, data)

export const newSequiDocForPostHiringStep4Service = (data) =>
    authAxios.post(EndPoints.newSequiDocForPostHiringStep4, data)

export const uploadSequiDocPdfDocumentService = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.uploadSequiDocPdfDocument, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })

export const getUploadedSequiDocPdfDocumentService = (id) =>
    authAxios.get(`${EndPoints.getUploadedSequiDocPdfDocument}/${id}`)

export const getNewSequiDocAdditionalDocumentListService = () =>
    authAxios.get(EndPoints.getNewSequiDocAdditionalDocumentList)

export const getUserListByOfficeAndPositionService = (body) =>
    authAxios.post(EndPoints.getUserListByOfficeAndPosition, body)
export const newInternalRecipientV1Service = (body) =>
    authAxios.post(EndPoints.newInternalRecipientV1, body)

export const newExternalRecipientV1Service = (body) =>
    authAxios.post(EndPoints.newExternalRecipientV1, body)
export const getConfigurationsV1Service = () => authAxios.get(EndPoints.getConfigurationsV1)
export const setConfigurationsV1Service = (body) =>
    authAxios.post(EndPoints.addConfigurationsV1, body)
export const getScreeningRequestsListV1Service = (body, signal) =>
    authAxios.post(EndPoints.getScreeningRequestsListV1, body, {signal})
export const deleteScreeningRequestsListV1Service = (body) =>
    authAxios.post(EndPoints.deleteScreeningRequestV1, body)
export const getUpdaterReportV1Service = (body) =>
    authAxios.post(EndPoints.getUpdaterReportV1, body)
export const updateReportStatusV1Service = (body) =>
    authAxios.post(EndPoints.updateReportStatusV1, body)

// SClearance
export const getPlanListsV1Service = () => authAxios.get(EndPoints.GetPlanListsV1)

export const getActivePlanV1Service = () => authAxios.get(EndPoints.getActivePlanV1)

export const activateSClearanceV1Service = (body) =>
    authAxios.post(EndPoints.activateSClearanceV1, body)

export const updateUserProfileSclearanceV1Service = (body) =>
    authAxios.post(EndPoints.updateUserProfileSclearanceV1, body)

export const disableSclearanceV1Service = (body) =>
    authAxios.post(EndPoints.disableSclearanceV1, body)

export const getSelectedSclearancePlanbyIdV1Service = (id = '') =>
    authAxios.get(`${EndPoints.getSelectedSclearancePlanbyIdV1}/${id}`)

export const getSclearanceEmployeDetailsbyIdV1Service = (employerId = '') =>
    authAxios.get(`${EndPoints.getSclearanceEmployeDetailsbyIdV1}/${employerId}`)

export const updateSclearancePlanV1Service = (body) =>
    authAxios.post(EndPoints.updateSclearancePlanV1, body)

export const getConfigByPositionV1Service = (body) =>
    authAxios.post(EndPoints.getConfigByPositionV1, body)

export const getSclearanceUserListByOfficeAndPositionV1Service = (body) =>
    authAxios.post(EndPoints.getSclearanceUserListByOfficeAndPositionV1, body)

export const sClearanceReportPusherV1Service = (body) =>
    authAxios.post(EndPoints.sClearanceReportPusherV1, body)

export const sClearanceManualPusherV1Service = (body) =>
    authAxios.post(EndPoints.sClearanceManualPusherV1, body)

export const getBackgroundCheckUserDetailV1Service = (userId) =>
    authAxios.get(`${EndPoints.getBackgroundCheckUserDetailV1}/${userId}`)

export const updatPersonalDetailsforBackgroundCheckV1Service = (body) =>
    authAxios.post(EndPoints.updatPersonalDetailsforBackgroundCheckV1, body)

export const getBackgroundVerificationQuestionsV1Service = (body) =>
    authAxios.post(EndPoints.getBackgroundVerificationQuestionsV1, body)

export const submitBackgroundVerificationAnswersV1Service = (body) =>
    authAxios.post(EndPoints.submitBackgroundVerificationAnswersV1, body)
export const validateRequestV1Service = (userId) =>
    authAxios.get(`${EndPoints.validateRequestV1}/${userId}`)

export const sClearanceBillingReportV1Service = (body, signal) =>
    authAxios.post(EndPoints.sClearanceBillingReportV1, body, {signal})

export const sClearanceReportV1Service = (body) =>
    authAxios.post(EndPoints.sClearanceReportV1, body)
export const resendEmailV1Service = (body) => authAxios.post(EndPoints.resendEmailV1, body)
export const addNewSclearanceV1Service = (body) =>
    authAxios.post(EndPoints.addNewSclearanceV1, body)

// Override
export const overridesBreakdownV1Service = (body) =>
    authAxios.post(EndPoints.overridesBreakdownV1, body)
export const getOverrideListV1Service = (body, signal) =>
    authAxios.post(EndPoints.overrideListV1, body, {signal})
export const getOverrideGraphV1Service = (body) => authAxios.post(EndPoints.overrideGraphV1, body)
export const generateOverrideV1Service = (params, signal) =>
    authAxios.get(EndPoints.generateOverrideV1, {params, signal})

// Pipeline
//Leads
export const getPipelineLeadStatusDataV1Service = (body, signal) =>
    authAxios.post(EndPoints.getPipelineLeadStatusV1, body, signal)
export const updateLeadStatusV1Service = (body) =>
    authAxios.post(EndPoints.updateLeadStatusV1, body)
export const updatePipelineBucketV1Service = (body) =>
    authAxios.post(EndPoints.updatePipelineBucketV1, body)
export const addAndUpdateBucketsV1Service = (body) =>
    authAxios.post(EndPoints.addAndUpdateBucketsV1, body)
export const getPipelineHistoryV1Service = (params) =>
    authAxios.get(EndPoints.getPipelineHistoryV1, {params})
export const getPipelineCommentsV1Service = (params) =>
    authAxios.get(`${EndPoints.getPipelineCommentsV1}/${params}`)
export const addPipelineCommentsV1Service = (body) =>
    authAxios.post(EndPoints.addPipelineCommentsV1, body)
export const getInterviewerV1Service = () => authAxios.get(EndPoints.getInterviewerV1)

//Onbarding
export const getOnboardingBucketDataV1Service = (body, signal) =>
    authAxios.post(EndPoints.getOnboardingBucketDataV1, body, {signal})

export const toggleBucketVisibilityV1Service = (body) =>
    authAxios.post(EndPoints.toggleBucketVisibilityV1, body)

export const getTemplateCategoryListForDropdownService = () =>
    authAxios.get(EndPoints.getTemplateCategoryListForDropdown)

export const getTemplateListForDropdownService = (id) =>
    authAxios.get(`${EndPoints.getTemplateListForDropdown}/${id}`)

export const testSmartTextTemplateService = (body) =>
    authAxios.post(EndPoints.testSmartTextTemplate, body)

export const sendNowSmartTextTemplateService = (body) =>
    authAxios.post(EndPoints.sendNowSmartTextTemplate, body)

export const sendTestOtherBlankTemplateService = (body) =>
    authAxios.post(EndPoints.sendTestOtherBlankTemplate, body)

export const sendTestOtherPdfTemplateService = (body) =>
    authAxios.post(EndPoints.sendTestOtherPdfTemplate, body)

export const sendOrTestDocumentToUploadService = (body) =>
    authAxios.post(EndPoints.sendOrTestDocumentToUpload, body)

export const sendTestOfferLetterTemplateService = (body) =>
    authAxios.post(EndPoints.sendTestOfferLetterTemplate, body)

export const sendNowOfferLetterTemplateService = (body) =>
    authAxios.post(EndPoints.sendOfferLetterTemplate, body)

export const sendNowOtherTemplatesService = (body) =>
    authAxios.post(EndPoints.sendNowOtherTemplates, body)

export const sendNowOtherPdfTemplatesService = (body) =>
    authAxios.post(EndPoints.sendNowOtherPdfTemplates, body)

export const deleteNewSequiDocsTemplateService = (id) =>
    authAxios.delete(`${EndPoints.deleteNewSequiDocsTemplate}/${id}`)

export const finishUploadPdfTemplateService = (body) =>
    authAxios.post(EndPoints.finishUploadPdfTemplate, body)

export const removeAndReassignPositionToTemplateService = (body) =>
    authAxios.post(EndPoints.removeAndReassignPositionToTemplate, body)

export const getSequiDocDocumentToUplodListService = () =>
    authAxios.get(EndPoints.getSequiDocDocumentToUplodList)

export const createNewDocumentToUploadTypeService = (body) =>
    authAxios.post(EndPoints.createNewDocumentToUploadType, body)

export const newSequiDocEmployeeDocumentListService = (params, signal) =>
    authAxios.get(EndPoints.newSequiDocEmployeeDocumentList, {params, signal})

export const newSequiDocEmployeeDocumentViewListService = (params, signal) =>
    authAxios.get(EndPoints.newSequiDocEmployeeDocumentViewList, {params, signal})

export const newSequiDocPeopleListByTemplateService = (params, signal) =>
    authAxios.get(EndPoints.newSequiDocPeopleListByTemplate, {params, signal})

export const testNewSequiDocTemplateAfterCreationService = (body) =>
    authAxios.post(EndPoints.testNewSequiDocTemplateAfterCreation, body)

export const removeAndAssignPositionTemplateService = (body) =>
    authAxios.post(EndPoints.removeAndAssignPositionTemplate, body)

export const validatePasswordForLoginService = (body) =>
    authAxios.post(EndPoints.validatePasswordForLogin, body)

export const getAllDocumentsToSignService = (params) =>
    authAxios.get(EndPoints.getAllDocumentsToSign, {params})

export const getSingleDocumentToSignService = (params) =>
    authAxios.get(EndPoints.getSingleDocumentToSign, {params})

export const getCoordinatedOFTextFromDocService = (params) =>
    authAxios.get(EndPoints.getCoordinatedOFTextFromDoc, {params})

export const getAllSignedDocumentsService = (body) =>
    authAxios.post(EndPoints.getAllSignedDocuments, body)

export const getDocumentToUploadForSignService = (id) =>
    authAxios.get(`${EndPoints.getDocumentToUploadForSign}/${id}`)

export const newSequiDocTemplateHistorynService = (id, params) =>
    authAxios.get(`${EndPoints.newSequiDocTemplateHistory}/${id}`, {params})

export const getPostDocumentVersionListService = (body) =>
    authAxios.post(EndPoints.getPostDocumentVersionList, body)

export const uploadFilledSignedDocumentService = (body) =>
    authAxios.post(EndPoints.uploadFilledSignedDocument, body)

export const applyFilledDataToDocumentService = (body) =>
    authAxios.post(EndPoints.applyFilledDataToDocument, body)

export const applyESignToDocumentService = (body) =>
    authAxios.post(EndPoints.applyESignToDocument, body)

export const applyDigitalSignToDocumentService = (body) =>
    authAxios.post(EndPoints.applyDigitalSignToDocument, body)

export const downloadSignedDocumentDocumentService = (body) =>
    authAxios.post(EndPoints.downloadSignedDocument, body)

export const getDownloadPathForUploadedDocumentService = (body) =>
    authAxios.post(EndPoints.getDownloadPathForUploadedDocument, body)

export const uploadPreHiringDocumentInSigningService = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.uploadPreHiringDocumentInSigning, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })

export const requestChangeForOfferLetterService = (body) =>
    authAxios.post(EndPoints.requestChangeForOfferLetter, body)

export const rejectOfferLetterService = (body) => authAxios.post(EndPoints.rejectOfferLetter, body)
export const getNewSequiDocDocumentAndOtherDetailsService = (body) =>
    authAxios.post(EndPoints.getNewSequiDocDocumentAndOtherDetails, body)

export const getUserNewSequiDocDocumentListService = (id) =>
    authAxios.get(`${EndPoints.getUserNewSequiDocDocumentList}/${id}`)

export const deletePreHiringDocumentInSigningService = (id) =>
    authAxios.delete(`${EndPoints.deletePreHiringDocumentInSigning}/${id}`)

export const newSequiDocPostHiringDocumentListService = (id) =>
    authAxios.get(`${EndPoints.newSequiDocPostHiringDocumentList}/${id}`)

export const newSequiDocOnBoardingUserDocumentListService = (id) =>
    authAxios.get(`${EndPoints.newSequiDocOnBoardingUserDocumentList}/${id}`)

export const sendOfferLetterMailToOnBoardingEmployeeService = (body) =>
    authAxios.post(EndPoints.sendOfferLetterMailToOnBoardingEmployee, body)

export const reSendNewSequiDocParticularDocumentService = (body) =>
    authAxios.post(EndPoints.reSendNewSequiDocParticularDocument, body)

export const reSendNewSequiDocParticularDocumentToUploadService = (body) =>
    authAxios.post(EndPoints.reSendNewSequiDocParticularDocumentToUpload, body)

export const getNewSequiDocDocumentCommentsService = (body) =>
    authAxios.post(EndPoints.getNewSequiDocDocumentComments, body)

export const addNewSequiDocDocumentCommentsService = (body) =>
    authAxios.post(EndPoints.addNewSequiDocDocumentComments, body)

export const getNewSequiDocOnBoardingUserByIdService = (id) =>
    authAxios.get(`${EndPoints.newSequiDocOnBoardingUserById}/${id}`)

export const getNewSequiDocOnBoardingUsersList = (params, signal) =>
    authAxios.get(EndPoints.newSequiDocOnBoardingUsers, {params, signal})

export const sendDocumetToExternalEmplopyeeService = (body) =>
    authAxios.post(EndPoints.sendDocumetToExternalEmplopyee, body)

export const sendDocumetToUploadToExternalEmplopyeeService = (body) =>
    authAxios.post(EndPoints.sendDocumetToUploadToExternalEmplopyee, body)

export const getExternalEmplopyeeDocumentList = (params, signal) =>
    authAxios.get(EndPoints.getExternalEmplopyeeDocumentList, {params, signal})

export const getExternalEmplopyeeProfileService = (params) =>
    authAxios.get(EndPoints.getExternalEmplopyeeProfile, {params})

export const updateExternalEmplopyeeNameService = (body) =>
    authAxios.post(EndPoints.updateExternalEmplopyeeName, body)

export const getStanderdNewSequiDocTemplateListService = () =>
    authAxios.get(EndPoints.getStanderdNewSequiDocTemplateList)

// Pending Pay
export const getPendingPayV1Service = (body) => authAxios.post(EndPoints.getPendingPayV1, body)

export const getPendingPayCommisssionV1Service = (body, signal) =>
    authAxios.post(EndPoints.getPendingPayCommisssionV1, body, {signal})

export const getPendingPayOverrideV1Service = (body, signal) =>
    authAxios.post(EndPoints.getPendingPayOverrideV1, body, {signal})

export const getPendingPayAdjustmentV1Service = (body, signal) =>
    authAxios.post(EndPoints.getPendingPayAdjustmentV1, body, {signal})

export const getPendingPayDeductionsV1Service = (body, signal) =>
    authAxios.post(EndPoints.getPendingPayDeductionsV1, body, {signal})

export const getPendingPayReconciliationV1Service = (body, signal) =>
    authAxios.post(EndPoints.getPendingPayReconciliationV1, body, {signal})

export const getPendingPayReimbursementV1Service = (body, signal) =>
    authAxios.post(EndPoints.getPendingPayReimbursementV1, body, {signal})

export const getPendingPayAdditionalValuesService = (body, signal) =>
    authAxios.post(EndPoints.getPendingPayAdditionalValues, body, {signal})

// Excel import
export const getPayollSettingV1Service = () => authAxios.get(EndPoints.getPayollSettingV1)

export const addPayrollSettingV1Service = (body) =>
    authAxios.post(EndPoints.addPayrollSettingV1, body)

export const deletePayrollSettingV1Service = (id) =>
    authAxios.delete(`${EndPoints.deletePayrollSettingV1}/${id}`)

export const getCustoFieldV1Service = () => authAxios.get(EndPoints.getCustoFieldV1)

export const updateCustomFieldV1Service = (body) =>
    authAxios.post(EndPoints.updateCustomFieldV1, body)

export const getAdditionalValueV1Details = (body) =>
    authAxios.post(EndPoints.getAdditionalValueDetailsV1, body)

export const exportCustomFieldsV1Service = (params) =>
    authAxios.get(EndPoints.exportCustomFieldsV1, {params})

export const importCustomFieldsV1Service = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.importCustomFieldsV1, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })
export const getOpenAPIChatV1Service = (body) => authAxios.post(EndPoints.getOpenAIChatV1, body)

//Advance payment request
export const updateAdvanceSettingService = (body) =>
    authAxios.post(EndPoints.updateAdvanceSetting, body)
export const getAdvanceSettingDetailsService = () => authAxios.get(EndPoints.getAdvanceSetting)
export const getRepaymentDetailsService = (params) =>
    authAxios.get(EndPoints.getRepaymentDetails, {params})
export const advancePaymentService = (body) => authAxios.post(EndPoints.advancePayment, body)

//SequiAI Integration
export const getSequiAiPlansV1Service = () => authAxios.get(EndPoints.getSequiAiPlans)
export const activateSequiAiV1Service = (body) => authAxios.post(EndPoints.activateSequiAi, body)

export const deleteAdditionalLocationService = (body) =>
    authAxios.post(EndPoints.deleteAdditionalLocation, body)

export const addNewDocumentToProfileService = (body) =>
    authAxios.post(EndPoints.addNewDocumentToProfile, body)

//SalesCRM
export const getSalesCRMPipelineV1Service = (body, signal) =>
    authAxios.post(EndPoints.getSalesCRMPipelineV1, body, {signal})

export const addUpdateSalesCRMBucketV1Service = (body) =>
    authAxios.post(EndPoints.addUpdateSalesCRMBucketV1, body)
export const deleteSalesBucketV1Service = (body) =>
    authAxios.post(EndPoints.deleteSalesBucketV1, body)
export const moveSalesJobV1Service = (body) => authAxios.post(EndPoints.moveSalesJobV1, body)
export const addSalesSubTaskV1Service = (body) => authAxios.post(EndPoints.addSalesSubTaskV1, body)
export const updatePreferencesV1Service = (body) =>
    authAxios.post(EndPoints.updatePreferencesV1, body)
export const deleteSubTaskV1Service = (body) => authAxios.post(EndPoints.deleteSubTaskV1, body)
export const deleteSalesJobV1Service = (body) => authAxios.post(EndPoints.deleteSalesJobV1, body)
export const getBucketSubTaskV1Service = (body) =>
    authAxios.post(EndPoints.getBucketSubTaskV1, body)
export const addSalesJobV1Service = (body) => authAxios.post(EndPoints.addSalesJobV1, body)
export const getBucketTaskInfoV1Service = (body) =>
    authAxios.post(EndPoints.getBucketTaskInfoV1, body)
export const getMoveBucketListV1Service = (body) =>
    authAxios.post(EndPoints.getMoveBucketListV1, body)
export const getAllBucketV1Service = () => authAxios.get(EndPoints.getAllBucketV1)
export const getSalesCRMPreferenceV1Service = () => authAxios.get(EndPoints.getSalesCRMPreferenceV1)
export const updateSubtaskV1Service = (body) => authAxios.post(EndPoints.updateSubtaskV1, body)
export const addSalesDocumentV1Service = (body) =>
    authAxios.post(EndPoints.addSalesDocumentV1, body)
export const getSalesDocumentsV1Service = (body) =>
    authAxios.post(EndPoints.getSalesDocumentsV1, body)
export const addSaleCommentsV1Service = (body) => authAxios.post(EndPoints.addSaleCommentsV1, body)
export const getSalesCommentV1Service = (body) => authAxios.post(EndPoints.getSalesCommentV1, body)
export const deleteAttachmentV1Service = (body) =>
    authAxios.post(EndPoints.deleteAttachmentV1, body)
export const exportSalesCRMDataV1Service = (body) =>
    authAxios.post(EndPoints.exportSalesCRMDataV1, body)
export const updateSalesCRMStatusV1Service = (body) =>
    authAxios.post(EndPoints.updateSalesCRMStatusV1, body)
export const upgradeSalesCRMPlanV1Service = (body) =>
    authAxios.post(EndPoints.upgradeSalesCRMPlanV1, body)
export const getCurrentActivePlanV1Service = (body) =>
    authAxios.post(EndPoints.getCurrentActivePlanV1, body)
export const deleteSalesCommentV1Service = (body) =>
    authAxios.post(EndPoints.deleteSalesCommentV1, body)
export const addUpdateCustomeFieldV1Service = (body) =>
    authAxios.post(EndPoints.addUpdateCustomeFieldV1, body)
export const deleteAdditionalFieldV1Service = (body) =>
    authAxios.post(EndPoints.deleteAdditionalFieldV1, body)
export const saveAdditionalFieldV1Service = (body) =>
    authAxios.post(EndPoints.saveAdditionalFieldV1, body)
export const getAdditionalFieldV1Service = () => authAxios.get(EndPoints.getAdditionalFieldV1)
export const importSalesCRMDataV1Service = (data, uploadStatus = () => {}) =>
    authAxios.post(EndPoints.importSalesCRMDataV1, data, {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent
            let completed = Math.floor((loaded * 100) / total)
            if (completed < 100) {
                uploadStatus({
                    total,
                    completed,
                })
            } else {
                uploadStatus({
                    total,
                    completed: 100,
                })
            }
        },
    })

//Leaderboard
export const getLearderBoardListV1Service = (body, signal) =>
    authAxios.post(EndPoints.getLearderBoardListV1, body, {signal})
export const getLearderBoardListByUserV1Service = (body, signal) =>
    authAxios.post(EndPoints.getLearderBoardListByUserV1, body, {signal})

// Admin report
export const getAdminReportPersonalSummaryService = (body) =>
    authAxios.post(EndPoints.getAdminReportPersonalSummary, body)
export const getAdminSnapshotSummaryService = (body) =>
    authAxios.post(EndPoints.getAdminSnapshotSummary, body)
export const getAdminReportDataService = (body) =>
    authAxios.post(EndPoints.getAdminReportData, body)
export const getAdminGraphDataService = (body) => authAxios.post(EndPoints.getAdminGraphData, body)
export const getProjectedDataService = (body) => authAxios.post(EndPoints.getProjectedData, body)

// -------------------- Standerd Recon Report --------------------
export const getOutstandingReconValueV1Service = (body) =>
    authAxios.post(EndPoints.getOutstandingReconValueV1, body)
export const getOutstandingReconGraphV1Service = (body) =>
    authAxios.post(EndPoints.getOutstandingReconGraphV1, body)
export const getUserReonBreakDownGraphV1Service = (body) =>
    authAxios.post(EndPoints.getUserReonBreakDownGraphV1, body)
export const getPastReconListsReportV1Service = (body) =>
    authAxios.post(EndPoints.getPastReconListsReportV1, body)
export const getReconDedutionReportV1Service = (body) =>
    authAxios.post(EndPoints.getReconDedutionReportV1, body)
export const getReconCommissionReportV1Service = (body) =>
    authAxios.post(`${EndPoints.getReconCommissionReportV1}`, body)
export const getReconClawbackReportV1Service = (body) =>
    authAxios.post(`${EndPoints.getReconClawbackReportV1}`, body)
export const getReconReportTopCVardsDataV1Service = (body) =>
    authAxios.post(`${EndPoints.getReconReportTopCVardsDataV1}`, body)
export const getReconReportMainGraphV1Service = (body) =>
    authAxios.post(`${EndPoints.getReconReportMainGraphV1}`, body)
export const getReconOverrideReportV1Service = (body) =>
    authAxios.post(`${EndPoints.getReconOverrideReportV1Service}`, body)
export const getReconAdjustmentReportV1Service = (body) =>
    authAxios.post(`${EndPoints.getReconAdjustmentReportV1}`, body)

export const getReconCommissionOverridesReportCardDetailV1Service = (body) =>
    authAxios.post(`${EndPoints.getReconCommissionOverridesReportCardDetail}`, body)

export const getReconViewReportV1Service = (body) =>
    authAxios.post(EndPoints.getReconViewReportV1, body)
export const getReconCommissionViewReportV1Service = (body) =>
    authAxios.post(EndPoints.getReconCommissionViewReportV1, body)
export const getReconClawbackViewReportV1Service = (body) =>
    authAxios.post(EndPoints.getReconClawbackViewReportV1, body)
export const getReconOverrideViewReportV1Service = (body) =>
    authAxios.post(EndPoints.getReconOverrideViewReportV1, body)
export const getReconAdjustmentViewReportV1Service = (body) =>
    authAxios.post(EndPoints.getReconAdjustmentViewReportV1, body)
