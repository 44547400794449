import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Paginator} from 'primereact/paginator'
import CustomDropdown from '../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {GLOBAL_PAGE_LIMT} from '../../../../../constants/constants'
import CustomInput, {
    INPUT_TYPE,
} from '../../../../../customComponents/customInputs/customInput/CustomInput'
import _ from 'lodash'
import {fontsFamily} from '../../../../../assets/fonts/fonts'
import './PaginationStyle.css'

export default function Pagination({
    page = 1,
    setPage = () => {},
    totalPages = 0,
    limit = 10,
    setLimit = () => {},
    containerWidth,
    showLimitPagination = true,
    showCustomPaginationInput = true,
    tableName = null,
}) {
    const [first, setFirst] = useState(0)
    const [inputPage, setInputedPage] = useState(page)

    const onInputPageChange = (e) => {
        if (e?.target?.value <= totalPages) {
            setInputedPage(e.target.value)
            updatedPage(e.target.value)
        }
    }
    const updatedPage = useCallback(
        _.debounce((val) => {
            setPage(val > 0 ? val : 1)
        }, 1000),
        []
    )

    const totalRecords = useMemo(() => totalPages * 10, [totalPages])

    const onPageChange = (event) => {
        setFirst(event.first)
        setPage(event.page + 1)
    }

    const handleInput = (e) => {
        setLimit(e.target.value)
        setPage(1)
    }

    useEffect(() => {
        if (page !== inputPage) {
            setInputedPage(page)
        }
        let pageVal = page * 10 - 10
        setFirst(pageVal)
    }, [page])

    return (
        <>
            <div
                className='d-flex align-items-center flex-wrap gap-3 flex-md-end flex-center py-3'
                style={{fontFamily: fontsFamily.manrope}}
            >
                <div>
                    <Paginator
                        id={tableName}
                        className='bg-transparent'
                        first={first}
                        rows={10}
                        totalRecords={totalRecords}
                        rowsPerPageOptions={10}
                        template='PrevPageLink PageLinks NextPageLink CurrentPageReport'
                        onPageChange={onPageChange}
                    />
                </div>

                {showLimitPagination ? (
                    <div className='d-flex flex-center gap-2'>
                        <div className='text-cmGrey600' style={{fontSize: 12, fontWeight: 500}}>
                            Rows per page:
                        </div>
                        <div>
                            <CustomDropdown
                                id={`${tableName}_Rows_Per_Page_Dropdown`}
                                value={limit}
                                onChange={handleInput}
                                options={GLOBAL_PAGE_LIMT}
                                searching={false}
                                showClear={false}
                                displayKey={'key'}
                            />
                        </div>
                    </div>
                ) : null}

                {showCustomPaginationInput ? (
                    <div className='d-flex flex-center gap-2'>
                        <div className='text-cmGrey600' style={{fontSize: 12, fontWeight: 500}}>
                            Go to:
                        </div>
                        <div>
                            <CustomInput
                                id={`${tableName}_go_to_Input`}
                                className='text-center'
                                type={INPUT_TYPE.number}
                                name='search'
                                onChange={onInputPageChange}
                                value={inputPage}
                                width={'w-50px'}
                                containerWidth={containerWidth}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    )
}
