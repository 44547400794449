import React from 'react'
import {fontsFamily} from '../../assets/fonts/fonts'

const CustomNoData = ({label = 'No data found', className}) => {
    return (
        <div
            className={`text-center text-md-bold text-sqGray500 ${className}`}
            style={{fontFamily: fontsFamily.manrope}}
        >
            {label}
        </div>
    )
}

export default CustomNoData
