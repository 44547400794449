import {Link} from 'react-router-dom'
import useCustomAccessRights from '../../accessRights/useCustomAccessRights'
import {useMemo} from 'react'

const RedirectToEmployeeProfile = ({
    employeeId = null,
    children,
    underline = true,
    className = null,
    state = null,
    style = null,
    target = '_self',
    route = null,
    id = null,
    textColor = 'cmGrey800',
}) => {
    const {employeeProfileAccess} = useCustomAccessRights()

    const redirectRoute = useMemo(() => {
        return route
            ? `${route}?employeeId=${employeeId}`
            : employeeProfileAccess.redirectRoute
            ? `${employeeProfileAccess.redirectRoute}?employeeId=${employeeId}`
            : `/user/personal-info?employeeId=${employeeId}`
    }, [employeeId, employeeProfileAccess.redirectRoute, route])

    const hasAccess = useMemo(() => {
        return redirectRoute ? true : false
    }, [redirectRoute])

    return (
        <Link
            id={id}
            to={hasAccess ? redirectRoute : ''}
            target={''}
            state={{
                employee_id: employeeId,
                ...state,
            }}
            style={{
                pointerEvents: hasAccess ? 'auto' : 'none',
            }}
        >
            <span
                className={`${
                    hasAccess ? 'cursor-pointer' : ''
                }  d-flex align-items-center ${className} text-${textColor}`}
                style={{
                    fontWeight: 700,
                    // ...(hasAccess && underline ? {textDecoration: 'underline'} : null),
                    ...style,
                }}
            >
                {children}
            </span>
        </Link>
    )
}

export default RedirectToEmployeeProfile
