import {createSlice} from '@reduxjs/toolkit'
import {APP_VERSION} from '../../constants/constants'

export const SettingInitialState = {
    appVersion: APP_VERSION,
    bb_branches: [],
    overdueBillingData: {
        isBillingOverdue: 0,
        overdueDays: 0,
        lastDueDate: null,
        paidStatus: false,
        closeDate: null,
    },
    timeZoneData: null,
    flag: true,
    companyProfile: null,
    payFrequencyType: [],
    userPositionData: null,
    payFrequencySetting: [],
    state_city: [],
    allStatesWithOffices: [],
    usedState: [],
    documentTypes: [],
    costCenterList: [],
    companySetting: null,
    reconciliationSchedule: [],
    positions: [],
    departments: [],
    allLocations: [],
    onBoardingConfigurations: [],
    costTracking: [],
    allNotification: [],
    singleNotification: {},
    notificationData: {},
    allReportingManager: [],
    parentCostCenterList: [],
    parentChildCostCenterList: [],
    setters: [],
    closers: [],
    managers: [],
    positionSetting: null,
    positionMainRoles: [],
    departmentWithPosition: [],
    officeList: [],
    positionList: [],
    weeklyPayPeriod: [],
    weeklyExecutedPayPeriod: [],
    monthlyPayPeriod: [],
    monthlyExecutedPayPeriod: [],
    semiMonthlyPayPeriod: [],
    semiExecutedMonthlyPayPeriod: [],
    biWeeklyPayPeriod: [],
    biWeeklyExecutedPayPeriod: [],
    companyOverrideSetting: null,
    companyReconciliationSetting: null,
    companyUpfrontSetting: null,
    companyCustomFieldSetting: [],
    companyAdvancePaymentSetting: null,
    salesCRMPreference: null,
    birthdayWish: [],
}

const SettingSlice = createSlice({
    name: 'setting',
    initialState: SettingInitialState,
    reducers: {
        SET_APP_VERSION: (state, action) => {
            state.appVersion = action.payload
        },
        SET_BB_BRANCHES: (state, action) => {
            state.bb_branches = action.payload
        },
        SET_COMPANY_PROFILE: (state, action) => {
            state.companyProfile = action.payload
        },
        SET_RECONCILIATION: (state, action) => {
            state.flag = action.payload
        },
        SET_USED_STATE: (state, action) => {
            state.usedState = action.payload
        },
        SET_STATE_CITY: (state, action) => {
            state.state_city = action.payload
        },
        SET_ALL_STATES_WITH_OFFICES: (state, action) => {
            state.allStatesWithOffices = action.payload
        },
        SET_USERACTIVE: (state, action) => {
            state.flag = action.payload
        },
        SET_PAY_FREQUENCY_TYPE: (state, action) => {
            state.payFrequencyType = action.payload
        },
        SET_PAY_FREQUENCY_SETTING: (state, action) => {
            state.payFrequencySetting = action.payload
        },
        SET_WEEKLY_PAY_PERIOD: (state, action) => {
            state.weeklyPayPeriod = action.payload
        },
        SET_WEEKLY_EXECUTED_PAY_PERIOD: (state, action) => {
            state.weeklyExecutedPayPeriod = action.payload
        },
        SET_MONTHLY_PAY_PERIOD: (state, action) => {
            state.monthlyPayPeriod = action.payload
        },
        SET_MONTHLY_EXECUTED_PAY_PERIOD: (state, action) => {
            state.monthlyExecutedPayPeriod = action.payload
        },
        SET_SEMI_MONTHLY_PAY_PERIOD: (state, action) => {
            state.semiMonthlyPayPeriod = action.payload
        },
        SET_SEMI_MONTHLY_EXECUTED_PAY_PERIOD: (state, action) => {
            state.semiExecutedMonthlyPayPeriod = action.payload
        },
        SET_BI_WEEKLY_PAY_PERIOD: (state, action) => {
            state.biWeeklyPayPeriod = action.payload
        },
        SET_BI_WEEKLY_EXECUTED_PAY_PERIOD: (state, action) => {
            state.biWeeklyExecutedPayPeriod = action.payload
        },
        SET_DOCUMENT_TYPES: (state, action) => {
            state.documentTypes = action.payload
        },
        SET_COST_CENTER_LIST: (state, action) => {
            state.costCenterList = action.payload
        },
        SET_COMPANY_SETTING: (state, action) => {
            state.companySetting = action.payload
        },
        SET_OVERDUE_BILLING_DATA: (state, action) => {
            state.overdueBillingData = {...state.overdueBillingData, ...action.payload}
        },
        SET_COMPANY_OVERRIDE_SETTING: (state, action) => {
            state.companyOverrideSetting = action.payload
        },
        SET_COMPANY_RECONCILIATION_SETTING: (state, action) => {
            state.companyReconciliationSetting = action.payload
        },
        SET_COMPANY_UPFRONT_SETTING: (state, action) => {
            state.companyUpfrontSetting = action.payload
        },
        SET_COMPANY_CUSTOM_FIELD_SETTING: (state, action) => {
            state.companyCustomFieldSetting = action.payload
        },
        SET_COMPANY_ADVANCE_PAYMENT_SETTING: (state, action) => {
            state.companyAdvancePaymentSetting = action.payload
        },
        SET_RECONCIALIATION_SCHEDULE: (state, action) => {
            state.reconciliationSchedule = action.payload
        },
        SET_POSITIONS: (state, action) => {
            state.positions = action.payload
        },
        SET_DEPARTMENTS: (state, action) => {
            state.departments = action.payload
        },
        SET_ALL_LOCATIONS: (state, action) => {
            state.allLocations = action.payload
        },
        SET_COST_TRACKING: (state, action) => {
            state.costTracking = action.payload
        },
        SET_ALL_NOTIFICATION: (state, action) => {
            state.allNotification = action.payload
        },
        SET_NOTIFICATION_DATA: (state, action) => {
            state.notificationData = action.payload
        },
        SET_ALL_REPORTING_MANAGER: (state, action) => {
            state.allReportingManager = action.payload
        },
        SET_SINGLE_NOTIFICATION: (state, action) => {
            state.singleNotification = action.payload
        },
        SET_PARENT_COST_CENTER_LIST: (state, action) => {
            state.parentCostCenterList = action.payload
        },
        SET_SETTERS: (state, action) => {
            state.setters = action.payload
        },
        SET_CLOSERS: (state, action) => {
            state.closers = action.payload
        },
        SET_POSITION_SETTING: (state, action) => {
            state.positionSetting = action.payload
        },
        SET_USER_POSITION_DATA: (state, action) => {
            state.userPositionData = action.payload
        },
        SET_MANAGERS: (state, action) => {
            state.managers = action.payload
        },
        SET_PARENT_CHILD_COST_CENTER_LIST: (state, action) => {
            state.parentChildCostCenterList = action.payload
        },
        SET_ONBORDING_CONFIGURATION: (state, action) => {
            state.onBoardingConfigurations = action.payload
        },
        SET_POSITION_MAIN_ROLES: (state, action) => {
            state.positionMainRoles = action.payload
        },
        SET_DEPARTMENT_WITH_POSITION: (state, action) => {
            state.departmentWithPosition = action.payload
        },
        SET_RECON_POSITION_LIST: (state, action) => {
            state.positionList = action.payload
        },
        SET_RECON_OFFICE_LIST: (state, action) => {
            state.officeList = action.payload
        },
        SET_SALES_CRM_PREFERENCE: (state, action) => {
            state.salesCRMPreference = action.payload
        },
        SET_BIRTHDAY_WISH: (state, action) => {
            state.birthdayWish = action.payload
        },
        SET_TIMEZONE_DATA: (state, action) => {
            state.timeZoneData = action.payload
        },
    },
})

const {actions, reducer: SettingReducer} = SettingSlice

export const {
    SET_BB_BRANCHES,
    SET_FLAG,
    SET_STATE_CITY,
    SET_RECONCILIATION,
    SET_USERACTIVE,
    SET_PAY_FREQUENCY_TYPE,
    SET_PAY_FREQUENCY_SETTING,
    SET_DOCUMENT_TYPES,
    SET_POSITION_SETTING,
    SET_USER_POSITION_DATA,
    SET_COST_CENTER_LIST,
    SET_COMPANY_SETTING,
    SET_COMPANY_OVERRIDE_SETTING,
    SET_COMPANY_RECONCILIATION_SETTING,
    SET_RECONCIALIATION_SCHEDULE,
    SET_POSITIONS,
    SET_DEPARTMENTS,
    SET_ALL_LOCATIONS,
    SET_COST_TRACKING,
    SET_SINGLE_NOTIFICATION,
    SET_NOTIFICATION_DATA,
    SET_ALL_NOTIFICATION,
    SET_PARENT_COST_CENTER_LIST,
    SET_PARENT_CHILD_COST_CENTER_LIST,
    SET_CLOSERS,
    SET_COMPANY_UPFRONT_SETTING,
    SET_COMPANY_CUSTOM_FIELD_SETTING,
    SET_COMPANY_ADVANCE_PAYMENT_SETTING,
    SET_SETTERS,
    SET_MANAGERS,
    SET_USED_STATE,
    SET_ONBORDING_CONFIGURATION,
    SET_POSITION_MAIN_ROLES,
    SET_COMPANY_PROFILE,
    SET_DEPARTMENT_WITH_POSITION,
    SET_OVERDUE_BILLING_DATA,
    SET_RECON_OFFICE_LIST,
    SET_RECON_POSITION_LIST,
    SET_ALL_STATES_WITH_OFFICES,
    SET_WEEKLY_PAY_PERIOD,
    SET_WEEKLY_EXECUTED_PAY_PERIOD,
    SET_MONTHLY_PAY_PERIOD,
    SET_MONTHLY_EXECUTED_PAY_PERIOD,
    SET_BI_WEEKLY_PAY_PERIOD,
    SET_BI_WEEKLY_EXECUTED_PAY_PERIOD,
    SET_SEMI_MONTHLY_PAY_PERIOD,
    SET_SEMI_MONTHLY_EXECUTED_PAY_PERIOD,
    SET_ALL_REPORTING_MANAGER,
    SET_SALES_CRM_PREFERENCE,
    SET_APP_VERSION,
    SET_BIRTHDAY_WISH,
    SET_TIMEZONE_DATA,
} = actions

export default SettingReducer
