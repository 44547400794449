import {useCallback, useEffect, useMemo, useState} from 'react'
import OfficePerformanceChart from './OfficePerformanceChart'
import CustomImage from '../../../../../customComponents/customImage/CustomImage'
import {useSelector} from 'react-redux'
import useOfficeLocation from '../../../../../hooks/useOfficeLocation'
import {useDispatch} from 'react-redux'
import {
    getSmOfficePeformanceSelector,
    getSmOfficePeformanceTeamSelector,
} from '../../../../../redux/selectors/DashboardSelectors'
import {
    smOfficePerformanceAction,
    smOfficePerformanceTeamAction,
} from '../../../../../redux/actions/DashboardActions'
import {getUserDataSelector} from '../../../../../redux/selectors/AuthSelectors'
import {formattedNumberFields, sendDataToReducer} from '../../../../../helpers/CommonHelpers'
import CustomDropdown from '../../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {Dashboard_DURATION_DROPDOWN_LIST} from '../../../../../constants/constants'
import CustomBarChartSkeleton from '../../../../../customComponents/customSkeleton/CustomBarChartSkeleton'
import {SET_DASHBOARD_FILTER_DATA} from '../../../../../redux/reducers/FilterReducer'
import {getDashboardFilterDataSelector} from '../../../../../redux/selectors/FilterSelectors'
import useSequifiConfig from '../../../../../sequiConfig/useSequifiConfig'
import Show from '../../../../../customComponents/show/Show'

const OfficePerformaceCard = () => {
    const dashboardData = useSelector(getDashboardFilterDataSelector)

    const officeSalesData = useSelector(getSmOfficePeformanceSelector)
    const officeSalesTeamData = useSelector(getSmOfficePeformanceTeamSelector)
    const [filter, setFilter] = useState(dashboardData?.standard?.office_performance)
    const [loading, setLoading] = useState(false)
    const [fullLoading, setFullLoading] = useState(false)
    const loggedUser = useSelector(getUserDataSelector)
    const [officeList, selectedLocation, setSelectedLocation] = useOfficeLocation()
    const {basedOnCompanyType} = useSequifiConfig()

    const dispatch = useDispatch()
    useEffect(() => {
        sendDataToReducer(dispatch, SET_DASHBOARD_FILTER_DATA, {
            standard: {
                office_performance: filter,
                sales_report: dashboardData?.standard?.sales_report,
            },
        })
        getOfficePerofmanceData()
        // getTopPayrollLocations()
    }, [filter])

    const onFilterChange = (e) => {
        setFilter(e.target.value)
    }
    const getOfficePerofmanceData = useCallback(() => {
        if (officeSalesData || officeSalesTeamData) setLoading(true)
        else setFullLoading(true)
        const body = {
            filter: filter,
            user_id: loggedUser?.id,
        }
        dispatch(smOfficePerformanceAction(body)).finally(() => {
            if (officeSalesData) setLoading(false)
            else setFullLoading(false)
        })
        dispatch(smOfficePerformanceTeamAction(body)).finally(() => {
            if (officeSalesTeamData) setLoading(false)
            else setFullLoading(false)
        })
    }, [dispatch, filter])
    // const getTopPayrollLocations = useCallback(() => {
    //     if (payrollSummaryData) setLoading(true)
    //     else setFullLoading(true)
    //     dispatch(getTopPayrollLocationsAction()).finally(() => {
    //         if (payrollSummaryData) setLoading(false)
    //         else setFullLoading(false)
    //     })
    // }, [dispatch, filter])
    const thisLastValueDisplayKey = useMemo(() => {
        return Dashboard_DURATION_DROPDOWN_LIST.find((item) => item?.value == filter)?.name
        let text = ''
        switch (filter) {
            case 'this_week':
                text = 'Week'
                break
            case 'this_year':
                text = 'Year'
                break
            case 'this_month':
                text = 'Month'
                break
            case 'this_quarter':
                text = 'Quarter'
                break
            default:
            // code block
        }
        return text
    }, [filter])

    return (
        <div
            className='p-18px pb-0 sqcard-shadow bg-cmwhite'
            style={{borderRadius: '10px', position: 'relative'}}
        >
            <div className='mb-24px text-md-bold text-sqGray900  d-flex justify-content-between gap-3 flex-wrap'>
                <div>Office Performance</div>
                <div>
                    <CustomDropdown
                        id={'Announcements_Office_Performance_Duration_Filter'}
                        options={Dashboard_DURATION_DROPDOWN_LIST}
                        onChange={onFilterChange}
                        value={filter}
                        searching={false}
                        showClear={false}
                    />
                </div>
            </div>
            <div className='d-flex flex-wrap gap-16'>
                <div className='w-lg-50 w-100 flex-grow-1'>
                    {loading || fullLoading ? (
                        <CustomBarChartSkeleton />
                    ) : (
                        <OfficePerformanceChart
                            chartData={officeSalesData}
                            thisLastValueDisplayKey={thisLastValueDisplayKey}
                        />
                    )}
                </div>
                {/* Side card begins */}
                <div className='mb-18px min-w-md-260px'>
                    {/* Hedings */}
                    <div className='d-flex align-items-center mb-2 gap-5px'>
                        <div className='text-sqGray800 text-xs-semibold min-w-70px'>
                            Total Sales:
                        </div>
                        <div className='text-sqGray900 text-xs-bold'>
                            {officeSalesData?.totalSales} Accounts
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-5px mb-24px'>
                        <Show>
                            <Show.When isVisible={basedOnCompanyType.isCompanyTypePest}>
                                <div className='text-sqGray800 text-xs-semibold min-w-70px'>
                                    Revenue:
                                </div>
                                <div className='text-sqGray900 text-xs-bold'>
                                    {formattedNumberFields(officeSalesData?.totalKw)}
                                </div>
                            </Show.When>
                            <Show.Else>
                                <div className='text-sqGray800 text-xs-semibold min-w-70px'>
                                    Total {basedOnCompanyType?.primaryUnitType}:
                                </div>
                                <div className='text-sqGray900 text-xs-bold'>
                                    {formattedNumberFields(officeSalesData?.totalKw, '')}{' '}
                                    {basedOnCompanyType?.primaryUnitType}
                                </div>
                            </Show.Else>
                        </Show>
                    </div>
                    {/* card 1 */}
                    <div
                        className='border border-sqGray200 px-3 py-1 mb-14px'
                        style={{borderRadius: 10}}
                    >
                        <div className='d-flex align-items-start gap-10px'>
                            <div className='text-cmGrey800 text-xs-semibold'>Top Team:</div>
                            {officeSalesTeamData?.top_team?.team ? (
                                <div>
                                    <div className='text-cmGrey700 text-sm-semibold mb-1'>
                                        {officeSalesTeamData?.top_team?.team}
                                    </div>
                                    <div className='d-flex align-items-center gap-2 text-cmBlue-Crayola text-xs-semibold'>
                                        <div>
                                            {formattedNumberFields(
                                                officeSalesTeamData?.top_team?.total_kw,
                                                ''
                                            )}{' '}
                                            {basedOnCompanyType?.primaryUnitType}
                                        </div>
                                        <div className=''>
                                            {officeSalesTeamData?.top_team?.total_account} Accounts
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='text-cmError text-xs-semibold ms-10px mb-7'>
                                    No Teams
                                </div>
                            )}
                        </div>
                    </div>
                    {/* card 2 */}
                    <Show>
                        <Show.When isVisible={!basedOnCompanyType.isCompanyTypePest}>
                            <div
                                className='border border-sqGray200 px-3 py-1 mb-14px'
                                style={{borderRadius: 10}}
                            >
                                <div className='d-flex align-items-start'>
                                    <div className='text-cmGrey800 text-xs-semibold me-1'>
                                        Top Setter:
                                    </div>
                                    {officeSalesTeamData?.setter?.setter_first_name ? (
                                        <>
                                            <div className='w-30px h-30px me-10px align-self-center'>
                                                <CustomImage
                                                    src={officeSalesTeamData?.setter?.setter_image}
                                                    className={'h-100 w-100'}
                                                />
                                            </div>
                                            <div>
                                                <div className='text-cmGrey700 text-sm-semibold mb-1'>
                                                    {officeSalesTeamData?.setter?.setter_first_name}{' '}
                                                    {officeSalesTeamData?.setter?.setter_last_name}
                                                </div>
                                                <div className='d-flex align-items-center flex-wrap gap-2 text-cmBlue-Crayola text-xs-semibold'>
                                                    <div>
                                                        <Show>
                                                            <Show.When
                                                                isVisible={
                                                                    basedOnCompanyType.isCompanyTypePest
                                                                }
                                                            >
                                                                {formattedNumberFields(
                                                                    officeSalesTeamData?.setter
                                                                        ?.total_kw
                                                                )}
                                                            </Show.When>
                                                            <Show.Else>
                                                                {formattedNumberFields(
                                                                    officeSalesTeamData?.setter
                                                                        ?.total_kw,
                                                                    ''
                                                                )}{' '}
                                                                {
                                                                    basedOnCompanyType?.primaryUnitType
                                                                }
                                                            </Show.Else>
                                                        </Show>
                                                    </div>
                                                    <div className='ms-10'>
                                                        {officeSalesTeamData?.setter?.total_account}{' '}
                                                        Accounts
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className='text-cmError text-xs-semibold ms-10px mb-7'>
                                            No Setter
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Show.When>
                    </Show>
                    {/* card 3 */}
                    <div
                        className='border border-sqGray200 px-3 py-1 mb-14px'
                        style={{borderRadius: 10}}
                    >
                        <div className='d-flex align-items-start'>
                            <div className='text-cmGrey800 text-xs-semibold me-1'>
                                <Show>
                                    <Show.When isVisible={basedOnCompanyType.isCompanyTypePest}>
                                        Top Sales Rep:
                                    </Show.When>
                                    <Show.Else>Top Closer:</Show.Else>
                                </Show>
                            </div>
                            {officeSalesTeamData?.closer?.closer_first_name ? (
                                <>
                                    <div className='w-30px h-30px me-10px align-self-centerpx'>
                                        <CustomImage
                                            src={officeSalesTeamData?.closer?.closer_image}
                                            className={'h-100 w-100'}
                                        />
                                    </div>
                                    <div>
                                        <div className='text-cmGrey700 text-sm-semibold mb-1'>
                                            {officeSalesTeamData?.closer?.closer_first_name}{' '}
                                            {officeSalesTeamData?.closer?.closer_last_name}
                                        </div>
                                        <div className='d-flex align-items-center flex-wrap gap-2 text-cmBlue-Crayola text-xs-semibold'>
                                            <div>
                                                <Show>
                                                    <Show.When
                                                        isVisible={
                                                            basedOnCompanyType.isCompanyTypePest
                                                        }
                                                    >
                                                        {formattedNumberFields(
                                                            officeSalesTeamData?.closer?.total_kw
                                                        )}
                                                    </Show.When>
                                                    <Show.Else>
                                                        {formattedNumberFields(
                                                            officeSalesTeamData?.closer?.total_kw,
                                                            ''
                                                        )}{' '}
                                                        {basedOnCompanyType?.primaryUnitType}
                                                    </Show.Else>
                                                </Show>
                                            </div>
                                            <div className=''>
                                                {officeSalesTeamData?.closer?.total_account}{' '}
                                                Accounts
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='text-cmError text-xs-semibold ms-10px mb-7'>
                                    <Show>
                                        <Show.When isVisible={basedOnCompanyType.isCompanyTypePest}>
                                            No Sales Rep
                                        </Show.When>
                                        <Show.Else>No Closer</Show.Else>
                                    </Show>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* card 4 */}
                    <div className='border border-sqGray200 px-3 py-1' style={{borderRadius: 10}}>
                        <div className='d-flex align-items-start'>
                            <div className='text-cmGrey800 text-xs-semibold me-1'>Top Manager:</div>
                            {officeSalesTeamData?.manager?.manager_first_name ? (
                                <>
                                    <div className='w-30px h-30px me-10px align-self-centerpx'>
                                        <CustomImage
                                            src={officeSalesTeamData?.manager?.manager_image}
                                            className={'w-100 h-100'}
                                        />
                                    </div>
                                    <div className='text-cmGrey700 text-sm-semibold mb-1'>
                                        <div>
                                            {officeSalesTeamData?.manager?.manager_first_name}{' '}
                                            {officeSalesTeamData?.manager?.manager_last_name}
                                        </div>
                                        <div className='d-flex align-items-center flex-wrap gap-2 text-cmBlue-Crayola text-xs-semibold'>
                                            <div>
                                                <Show>
                                                    <Show.When
                                                        isVisible={
                                                            basedOnCompanyType.isCompanyTypePest
                                                        }
                                                    >
                                                        {formattedNumberFields(
                                                            officeSalesTeamData?.manager?.total_kw
                                                        )}
                                                    </Show.When>
                                                    <Show.Else>
                                                        {formattedNumberFields(
                                                            officeSalesTeamData?.manager?.total_kw,
                                                            ''
                                                        )}{' '}
                                                        {basedOnCompanyType?.primaryUnitType}
                                                    </Show.Else>
                                                </Show>
                                            </div>
                                            <div className=''>
                                                {officeSalesTeamData?.manager?.total_account}{' '}
                                                Accounts
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='text-cmError text-xs-semibold ms-10px mb-7'>
                                    No Manager
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfficePerformaceCard
