import OneSignal from 'react-onesignal'
import {ONE_SIGNAL_APP_ID} from '../constants/constants'
import {store} from '../redux/store'
import {getIsLoggedInSelector} from '../redux/selectors/AuthSelectors'
import {updateDeviceTokenService} from '../services/Services'

const oneSignal = {
    init: () => {
        OneSignal.init({
            appId: ONE_SIGNAL_APP_ID,
            allowLocalhostAsSecureOrigin: true,
            welcomeNotification: {
                title: 'Sequifi',
                message: 'Thanks for subscribing!',
            },
        })
            .then(() => {
                console.log('One Signal Initiated')
                oneSignal.promptForNotificationPermissionAndGetPlayerId()
            })
            .catch((e) => {
                console.log('One Signal Not Initiated', e)
            })
    },
    promptForNotificationPermissionAndGetPlayerId: () =>
        new Promise((resolve) => {
            const isPushNotificationEnabled = OneSignal.Notifications.permission
            if (!isPushNotificationEnabled) {
                navigator.permissions
                    .query({name: 'notifications'})
                    .then(async (status) => {
                        console.log('OneSignal Notification Status: ', status.state)
                        if (['denied', 'prompt'].includes(status?.state)) {
                            await OneSignal.Slidedown.promptPush({
                                force: true,
                                forceSlidedownOverNative: true,
                            })
                            oneSignal
                                .getOneSignalId()
                                .then((playerId) => {
                                    resolve(playerId)
                                    console.log('OneSignal ID: ', playerId)
                                })
                                .catch(() => {
                                    resolve('')
                                })
                                .catch(() => {
                                    resolve('')
                                })
                        }
                    })
                    .catch(() => {
                        resolve('')
                    })
            } else {
                oneSignal.getOneSignalId().then((playerId) => {
                    resolve(playerId)
                    console.log('OneSignal PlayerID: ', playerId)
                })
            }
        }),
    getOneSignalId: () =>
        new Promise((resolve, reject) => {
            const state = store.getState()
            const isUserLoggedIn = getIsLoggedInSelector(state)
            if (isUserLoggedIn && OneSignal?.User?.PushSubscription?.id) {
                updateDeviceTokenService(OneSignal.User.PushSubscription.id).then(() => {
                    console.log(
                        'Device Token updated to Sequifi',
                        OneSignal.User.PushSubscription.id
                    )
                })
            } else {
                if (OneSignal?.User?.PushSubscription?.id) {
                    console.log('OneSignal : Not Logged In')
                } else {
                    console.log('OneSignal : Not Logged In + No OneSignal ID is there')
                }
            }
            resolve(OneSignal?.User?.PushSubscription?.id)
        }),
    login: (externalId, jwtToken) =>
        OneSignal.login(`onesignalid-${externalId}`, jwtToken)
            .then(() => oneSignal.getOneSignalId())
            .catch(() => {}),
    logout: (externalId, jwtToken) => {
        return OneSignal.logout()
    },
    addNotificationSettingChangeListener: (method) =>
        OneSignal.Notifications.addEventListener('permissionChange', method),
    removeNotificationSettingChangeListener: () =>
        OneSignal.Notifications.removeEventListener('permissionChange'),
}

export default oneSignal
