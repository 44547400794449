import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'

import {
    getAllStatesAndCitiesAction,
    getCompanySettingAction,
    getPositionSettingAction,
    getCompanyProfileAction,
    getCompanyProfileWithoutAuthAction,
    getAllStatesWithOfficesAction,
    getAllLocationListAction,
    getWeeklyPayPeriodAction,
    getMonthlyPayPeriodAction,
    getCompanyOverrideSettingAction,
    getPayFrequencySettingAction,
    getPositionsAction,
    getBiWeeklyPayPeriodAction,
    getSemiMonthlyPayPeriodAction,
    getBitbucketBranchesAction,
    getUserPositionDataAction,
    getCompanyReconciliationSettingAction,
} from '../redux/actions/SettingActions'
import {getIsLoggedInSelector, getUserDataSelector} from '../redux/selectors/AuthSelectors'
import {getUserProfileAction} from '../redux/actions/AuthActions'
import useDocumentTitle from '../hooks/useDocumentTitle'
import withPusher from '../hoc/withPusher'
import {INTEGRATIONS_ID, PUSHER_EVENTS, getValidDate} from '../constants/constants'
import {
    sClearanceReportPusherV1Service,
    sClearanceManualPusherV1Service,
} from '../services/Services'
import {getIntegrationListSelector} from '../redux/selectors/IntegrationSelectors'
import {getIntegratioListnAction} from '../redux/actions/IntegrationActions'
import useSequifiConfig from '../sequiConfig/useSequifiConfig'
import {BirthdayWishModal} from './modules/common/birthdayWish/BirthdayWishes'
import useIsAccountBillingDue from '../hooks/useIsAccountBillingDue'

const PreLoadPage = ({children, listenPusherEvent, pusherChannel}) => {
    const dispatch = useDispatch()
    const {isBillingOverdue} = useIsAccountBillingDue()
    const isLoggedIn = useSelector(getIsLoggedInSelector)
    const integrationList = useSelector(getIntegrationListSelector)
    const {basedOnHost} = useSequifiConfig()
    const checkSclearnceActive = useMemo(
        () =>
            integrationList?.length > 0
                ? integrationList?.find(
                      (val) => val?.id == INTEGRATIONS_ID.sClearance && val?.status == 1
                  )?.status
                : false,
        [integrationList]
    )

    useDocumentTitle()

    useEffect(() => {
        if (pusherChannel) {
            // listenPusherEvent(PUSHER_EVENTS.permission, (eventData) => {
            //     dispatch(getUserProfileAction())
            // })
            if (checkSclearnceActive) {
                listenPusherEvent(PUSHER_EVENTS.SClearannceReport, (eventData) => {
                    sClearanceReportPusherV1Service(eventData)
                })

                listenPusherEvent(PUSHER_EVENTS.SClearannceManual, (eventData) => {
                    sClearanceManualPusherV1Service(eventData)
                })
            }
        }
    }, [pusherChannel, checkSclearnceActive])

    const shouldCallPreLoadAPI = useMemo(() => {
        return (
            !window.location.pathname.includes('document-signing') &&
            isLoggedIn &&
            !isBillingOverdue
        )
    }, [isBillingOverdue, isLoggedIn])

    useEffect(() => {
        if (basedOnHost.developerConfig) dispatch(getBitbucketBranchesAction())

        if (shouldCallPreLoadAPI) {
            dispatch(getCompanyProfileAction())
                .finally(() => {
                    dispatch(getCompanySettingAction())
                        .finally(() => {
                            dispatch(getIntegratioListnAction())
                                .finally(() => {
                                    dispatch(getCompanyOverrideSettingAction())
                                        .finally(() => {
                                            dispatch(getCompanyReconciliationSettingAction())
                                                .finally(() => {
                                                    dispatch(getUserProfileAction())
                                                        .finally(() => {
                                                            dispatch(getPositionSettingAction())
                                                                .finally(() => {
                                                                    dispatch(
                                                                        getAllStatesWithOfficesAction()
                                                                    )
                                                                        .finally(() => {
                                                                            dispatch(
                                                                                getAllLocationListAction()
                                                                            )
                                                                                .finally(() => {
                                                                                    dispatch(
                                                                                        getAllStatesAndCitiesAction()
                                                                                    )
                                                                                        .finally(
                                                                                            () => {
                                                                                                dispatch(
                                                                                                    getWeeklyPayPeriodAction()
                                                                                                )
                                                                                                    .finally(
                                                                                                        () => {
                                                                                                            dispatch(
                                                                                                                getMonthlyPayPeriodAction()
                                                                                                            )
                                                                                                                .finally(
                                                                                                                    () => {
                                                                                                                        dispatch(
                                                                                                                            getBiWeeklyPayPeriodAction()
                                                                                                                        )
                                                                                                                            .finally(
                                                                                                                                () => {
                                                                                                                                    dispatch(
                                                                                                                                        getSemiMonthlyPayPeriodAction()
                                                                                                                                    )
                                                                                                                                        .finally(
                                                                                                                                            () => {
                                                                                                                                                dispatch(
                                                                                                                                                    getPayFrequencySettingAction()
                                                                                                                                                ).catch(
                                                                                                                                                    () => {}
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        )
                                                                                                                                        .catch(
                                                                                                                                            () => {}
                                                                                                                                        )
                                                                                                                                }
                                                                                                                            )
                                                                                                                            .catch(
                                                                                                                                () => {}
                                                                                                                            )
                                                                                                                    }
                                                                                                                )
                                                                                                                .catch(
                                                                                                                    () => {}
                                                                                                                )
                                                                                                        }
                                                                                                    )
                                                                                                    .catch(
                                                                                                        () => {}
                                                                                                    )
                                                                                            }
                                                                                        )
                                                                                        .catch(
                                                                                            () => {}
                                                                                        )
                                                                                })
                                                                                .catch(() => {})
                                                                        })
                                                                        .catch(() => {})
                                                                })
                                                                .catch(() => {})
                                                        })
                                                        .catch(() => {})
                                                })
                                                .catch(() => {})
                                        })
                                        .catch(() => {})
                                })
                                .catch(() => {})
                        })
                        .catch(() => {})
                })
                .catch(() => {})
        } else {
            if (isLoggedIn) dispatch(getUserProfileAction())
            dispatch(getAllStatesAndCitiesAction()).catch(() => {})
            dispatch(getCompanyProfileWithoutAuthAction()).catch(() => {})
        }
    }, [shouldCallPreLoadAPI])

    return (
        <>
            {children}
            <BirthdayWishModal />
        </>
    )
}

export default withPusher(PreLoadPage)
