import React, {useMemo, useState} from 'react'
import {fontsFamily} from '../../assets/fonts/fonts'
import {getUserPositionMetaData} from '../../helpers/CommonHelpers'

const CustomUserAvatar = ({
    image = null,
    username = null,
    showImageError = true,
    positionData = null,
    style = null,
    isLogo = false,
    objectFit = 'cover',
    fontSize = 12,
    margin = 'me-3',
    opacity = 25,
}) => {
    const [isImageBroken, setIsImageBroken] = useState(false)
    const onImageError = (e) => {
        if (!isImageBroken) setIsImageBroken(true)
    }
    const fullName = username?.split(' ')
    const initialLetters = fullName?.slice(0, 2)?.map((word) => word?.charAt(0)?.toUpperCase())
    const textBackground = useMemo(() => {
        return getUserPositionMetaData(positionData)?.position_color
    }, [positionData])

    return (
        <div style={{fontFamily: fontsFamily.manrope, textDecoration: 'none', fontSize: fontSize}}>
            {!isImageBroken && image && !image?.includes('default-user.png') ? (
                <img
                    alt='sequifi'
                    style={{
                        objectFit: objectFit,
                        ...style,
                    }}
                    {...(showImageError
                        ? {onError: (e) => (showImageError ? onImageError(e) : null)}
                        : null)}
                    src={image}
                    className={`${isLogo ? '' : `avatar ${margin}`}`}
                />
            ) : (
                <span
                    className={`${
                        isLogo ? '' : `avatar ${margin}`
                    } bg-opacity-${opacity} text-decoration-none d-flex flex-center text-cmGrey700 bg-${
                        textBackground ? textBackground : 'cmGrey400'
                    }`}
                    style={{
                        objectFit: objectFit,
                        textDecoration: 'none',
                        ...style,
                    }}
                >
                    {initialLetters?.join('')}
                </span>
            )}
        </div>
    )
}

export default CustomUserAvatar
